import { Component, OnInit,ElementRef,ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { ServerApiService } from "app/services/server-api.service";
import { StudentDataService } from "app/services/students/student-data.service";

@Component({
  selector: "app-student-cert",
  templateUrl: "./student-cert.component.html",
  styleUrls: ["./student-cert.component.css"],
})
export class StudentCertComponent implements OnInit {
  @ViewChild('myDialog', { static: true }) myDialog!: ElementRef;
  studentCert$: Observable<any>|null = null;
  theurl:Blob|null = null
  documentUrl: any = null
  
  constructor(
    private ServerApiService: ServerApiService,
    private StudentDataService: StudentDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const data = {
      // 'course_name': (this.fullPath) ? 'pathname' : this.selectedCourse.courseName,
      id: this.StudentDataService.getStudentInfo().studentID,
      fname: this.StudentDataService.getStudentInfo().firstName,
      lname: this.StudentDataService.getStudentInfo().familyName,
    };
    this.ServerApiService.buildStudentCert(data, "verifyed_student").subscribe(
      (data: any) => {
        console.log('student-cert.component,buildStudentCert,  data ============>:',data)
        if (data.status) {
          this.studentCert$ = this.ServerApiService.getDocument(
            "verifyed_student.pdf",
            `secretary_docs/issued/${
              this.StudentDataService.getStudentInfo().studentID
            }`
          );
        }
        if (data.SolarAge) {
          this.studentCert$ = this.ServerApiService.getDocument(
            "verifyed_student.pdf",
            `secretary_docs/solaAgeCertification/${
              this.StudentDataService.getStudentInfo().studentID
            }`
            // "solaAgeCertification2.pdf",
            // `public/docs/`
          );
        }
      }
      );
      // if(! this.documentUrl){
      //   this.myDialog.nativeElement.showModal();
      // }
  }
 // this.myDialog.nativeElement.showModal();
 //this.myDialog.nativeElement.close();

  newCretTicket(theurl) {
    // const ticket = {
    //   msg: 'Request to get student certificat',
    //   owner: this.StudentDataService.getStudentInfo().studentID,
    //   customer: this.StudentDataService.getStudentInfo().studentID,
    //   the_type: "document request",
    //   process: {
    //     status: 'open',
    //     open_date: new Date(Date.now()),
    //     close_date: null
    //   },

    // };
    // const actions = [{
    //   owner: this.StudentDataService.getStudentInfo().studentID,
    //   the_type: 'request',
    //   timestamp: new Date(Date.now()),
    //   description: 'new request to get student certificat',
    //   data: null
    // }, {
    //   owner: "system",
    //   the_type: 'close',
    //   timestamp: new Date(Date.now()),
    //   description: 'document supplied',
    //   data: null
    // }]
    this.documentUrl = URL.createObjectURL(
      new Blob([theurl], { type: "application/pdf" })
    );
    // if(this.documentUrl){
    //   this.myDialog.nativeElement.close();
    // }
    const ticketDetails = {
      owner: this.StudentDataService.getStudentInfo().studentID,
      customer: this.StudentDataService.getStudentInfo().studentID,
      sectionOf: "cert",
    };

    // this.ServerApiService.newDocTicket({ ticketDetails }).subscribe(
    //   (data: any) => {
    //     // this.ServerApiService.newAction(actions, data.data._id)
    //     //   .subscribe(data => {
    //     window.open(this.documentUrl);
    //     this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //     this.router.onSameUrlNavigation = "reload";
    //     this.router.navigate(["/administration"]);
    //   }
    // );
  }
}
