import { Component, OnInit, Input, EventEmitter } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";

import { ServerApiService } from "app/services/server-api.service";
import { StudentDataService } from "app/services/students/student-data.service";
import { ModalService } from "app/modules/shared/components/modal/modal.service";
import { FileServiceService } from "app/services/files/file-service.service";
import { IsLoggedService } from "app/services/data-share/is-logged.service";

import { LessonDate } from "app/models/interfaces/date";

@Component({
  selector: "app-file-holder",
  templateUrl: "./file-holder.component.html",
  styleUrls: ["./file-holder.component.css"],
  providers: [ServerApiService],
})
export class FileHolderComponent implements OnInit {
  @Input() lesson;
  @Input() mode;
  isfileNotExist :boolean = false
  displayStyle = "none";
  uploadPointer;
  id;
  updateNodeId: any;
  updateNodeContent: string;
  dateTime: LessonDate;
  quillEditorForm: FormGroup;
  quillUpdateEditorForm: FormGroup;

  usrProfile$: Observable<any>;
  allHomework$: Observable<any>;
  homework$: Observable<any>;
  homeworkStudents$: Observable<any>;
  classwork$: Observable<any>;
  classworkCycle$: Observable<any>;
  notes$: Observable<any>;
  path$: Observable<any>;
  courses$: Observable<any>;
  loadData: EventEmitter<any>;
  modalWidth: number;
  x: number;
  y: number;
  constructor(
    private ServerApiService: ServerApiService,
    private StudentDataService: StudentDataService,
    private modalService: ModalService,
    private FileServiceService: FileServiceService,
    private IsLoggedService: IsLoggedService
  ) {
    this.modalWidth = Math.floor(document.body.offsetWidth * 0.6);
    this.x = Math.floor(document.body.offsetWidth * 0.2);
    this.y = Math.floor(document.body.offsetHeight * 0.2);
  }

  fileNotExist(): void {
    this.isfileNotExist = true
    this.displayStyle = "block";
  }

  closePopup() {
    this.displayStyle = "none";
    this.isfileNotExist = false;
  }
  getClasswork(): void {
    const path = `${this.lesson.courseName}/${this.lesson.session_num}/`;
   // console.log('getClasswork , path:',path)
    this.classwork$ = this.FileServiceService.getData("classwork", path);
  }
  getAllHomework(): void {
    const path = `${this.lesson.courseName}/${this.lesson.session_num}/`;
 //   console.log('getAllHomework , path:',path)
    this.allHomework$ = this.ServerApiService.downloadBulck(path + "homework/");
  }
  getHomework(): void {
    const path = `${this.lesson.courseName}/${this.lesson.session_num}/`;
   // console.log('getHomework , path:',path)
    this.homework$ = this.FileServiceService.getData("homework", path);
  }
  getHomeworkStudents(): void {
    const path = `${this.lesson.courseName}/${this.lesson.session_num}/`;
    const hwsQuery = this.mode === 1 ? { id: this.id } : "";
 //   console.log(`getHomeworkStudents , path:${path} ,hwsQuery :${hwsQuery} `)
    this.homeworkStudents$ = this.FileServiceService.getData(
      "homeworkStudents",
      path,
      hwsQuery
    );
  }
  getClassworkCycle(): void {
    const path = `${this.lesson.courseName}/${this.lesson.session_num}/`;
    this.classworkCycle$ = this.FileServiceService.getData(
      "classworkCycle",
      path,
      null,
      this.lesson
    );
  }
  getNotes(): void {
    this.notes$ = this.FileServiceService.getData(
      "notes",
      null,
      null,
      this.lesson
    );
  }
  ngOnInit(): void {
    this.quillEditorForm = new FormGroup({ editor: new FormControl(null) });
    this.quillUpdateEditorForm = new FormGroup({
      editor: new FormControl(null),
    });
    this.dateTime = this.getReformatedDates();

    let num = parseInt( this.dateTime.date.month);
    num++
    this.dateTime.date.month = num.toString()
    //console.log(' .this.lesson===============>', this.lesson)

    this.id = this.StudentDataService.getStudentInfo().studentID;
    this.initObservables();
  }
  initObservables(): void {
    this.usrProfile$ = this.IsLoggedService.userProfile;
    this.path$ = this.FileServiceService.getData(
      "path",
      null,
      null,
      this.lesson
    );
    this.courses$ = this.ServerApiService.fetchCycleStudents(
      this.lesson.cyclecode
    );
    this.getAllHomework();
    this.getClasswork();
    this.getClassworkCycle();
    this.getHomework();
    this.getHomeworkStudents();
    this.getNotes();
  }
  triggerLoad() {
    this.loadData.emit();
  }
  removeNote(id) {
    this.ServerApiService.removeNote(id).subscribe(
      (res) => this.ngOnInit(),
      (err) => console.log(err)
    );
  }

  async addNote(lesson) {
    const res = await this.path$.toPromise();
    const path = `${res.paths > 1 ? res.paths[0] : "shared"},${
      lesson.courseName
    },${lesson.session_num},${lesson.cyclecode}`;
    const content = this.quillEditorForm.get("editor").value;
    if (!content) {
      this.openModal(
        "no-note-error",
        this.x * 2,
        this.y * 2,
        this.modalWidth * 0.5
      );
      return;
    }
    this.ServerApiService.addNewNote({ path: path, note: content }).subscribe(
      (res) => this.ngOnInit(),
      (err) => console.log(err)
    );
    this.closeModal("add-note-modal" + lesson.session_num);
  }

  async updateNote(lesson: any) {
    const res = await this.path$.toPromise();
    const path = `${res.paths > 1 ? res.paths[0] : "shared"},${
      lesson.courseName
    },${lesson.session_num},${lesson.cyclecode}`;
    const content = this.quillUpdateEditorForm.get("editor").value;
    if (!content) {
      this.openModal(
        "no-note-error",
        this.x * 2,
        this.y * 2,
        this.modalWidth * 0.5
      );
      return;
    }
    this.ServerApiService.updateNote({
      id: this.updateNodeId,
      path: path,
      note: content,
    }).subscribe();
    this.ngOnInit();
    this.closeModal("update-note-modal" + lesson.session_num);
  }

  openModal(
    id: string,
    x: number,
    y: number,
    l: number,
    nodeContent?: string,
    nodeId?: any
  ) {
    if (nodeContent && nodeId) {
      this.updateNodeContent = nodeContent;
      this.quillUpdateEditorForm.get("editor").setValue(nodeContent);
      this.updateNodeId = nodeId;
    }
    this.modalService.open(id, x, y, l);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  getReformatedDates(): LessonDate {
    const start = new Date(this.lesson.sessionDate);
    const end = new Date(this.lesson.sessionEndDate);
    const decorate = (num) => {
      return num < 10 ? "0" + num : num;
    };
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return {
      date: {
        year: start.getUTCFullYear().toString(),
        month: decorate(start.getUTCMonth().toString()),
        day: decorate(start.getDate().toString()),
      },
      time: {
        dayOfWeek: days[start.getUTCDay()],
        start: {
          hours: decorate((start.getHours()).toString()),
          minutes: decorate(start.getMinutes().toString()),
        },
        end: {
          hours: decorate((end.getHours()).toString()),
          minutes: decorate(end.getMinutes().toString()),
        },
      },
    };
  }

  dw(blob) {
    const url = URL.createObjectURL(
      new Blob([blob], { type: "application/zip" })
    );
    window.open(url);
  }
}
