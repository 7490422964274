import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { switchMap, catchError, tap  } from 'rxjs/operators';
import { forkJoin, of,EMPTY, throwError } from 'rxjs';
import { ServerApiService } from "app/services/server-api.service";
import { Router } from "@angular/router";
import { AuthService } from "app/services/auth/auth.service";
import { IsLoggedService } from "app/services/data-share/is-logged.service";
import { User } from "app/models/interfaces/user/user";
import { Token } from "app/models/interfaces/token";
import {SocketService} from "app/services/socket.service"

@Component({
  moduleId: module.id,
  selector: "login-cmp",
  templateUrl: "login.component.html",
  styleUrls: ["login.component.css"],
  providers: [ServerApiService],
})
export class LoginComponent implements OnInit {
  @ViewChild('loginDialog', { static: true }) loginDialog!: ElementRef;
  user = "";
  password = "";
  loginError = false;
  loginErrorText = "";
  isLoaded: boolean = false;
  options = {
    enableHighAccuracy: true, // Provides a hint to try to get the best possible results
    timeout: 5000, // The maximum length of time (in milliseconds) the device is allowed to take in order to return a position
    maximumAge: 0 // Indicates that the application wants to receive the most recent location
  };
  geoLocationData: {latitude: number, longitude: number}
  constructor(
    private serverApiService: ServerApiService,
    private IsLoggedService: IsLoggedService,
    private AuthService: AuthService,
    private router: Router,
    socketService: SocketService
  ) {}
  ngOnInit() {
    console.log('LoginComponent:',this.user);
    
    this.IsLoggedService.setUserProfile("Login");
    this.serverApiService.checkTokenValidity().subscribe(
      (token: Token) => {
        if (token.status) {
          this.serverApiService.loginWithToken().subscribe(
            (data) => {
              // bring to format
              let theuser: any = { user: data[0] };
              let user: User = theuser;
              // **************** */
              this.updateStateSuccsess(user);
              this.isLoaded = true;
            },
            (err) => this.updateStateFail(err)
          );
        }
        if (!token.status) {
          this.isLoaded = true;
        }
      },
      (err) => {
        console.log("server error");
        this.isLoaded = true;
      }
    );

  }


  updateStateSuccsess(data: User) {
    this.IsLoggedService.setUserLoggedIn(true);
    this.AuthService.login();
    this.IsLoggedService.setUserData(data);
    if (data.user.role === 1) {
      this.IsLoggedService.setUserProfile("Student");
      this.router.navigate(["/table-list"]);
    } else if (data.user.role === 2) {
      this.IsLoggedService.setUserProfile("Teacher");
      this.router.navigate(["/teacher-list"]);
    }else if (data.user.role === 4) {
      this.IsLoggedService.setUserProfile("Guest");
      this.router.navigate(["/solaredge-course-list"]);
    }if (data.user.role === 2000) {
      this.IsLoggedService.setUserProfile("Admin");
      this.router.navigate(["/admin-page"]);
    }
  }

  updateStateFail(error: any) {
    this.IsLoggedService.setUserLoggedIn(false);
    this.IsLoggedService.setUserProfile("Login");
    this.AuthService.logout();
    console.log("ERROR:", error);
    this.loginErrorText = "Login error " + error.error.text;
    this.loginError = true;
    this.user = "";
    this.password = "";
  }

  getLogin(event?: KeyboardEvent) {
    console.log('fetchLoginNew in getLogin cmp on send ')
    this.loginError = false;
    if (!this.user.trim() && !this.password.trim()) {
      return;
    }
    if ("geolocation" in navigator) {
      console.log(' /* geolocation is available */');
      this.serverApiService.fetchLoginNew(this.user, this.password).pipe(
        tap((data: User) => {
          console.log('User Logged In', data);
          this.updateStateSuccsess(data);
              // Combine the subsequent fetches
        }),
        switchMap((data: User)=>{
            const internship$ = this.serverApiService.isStudentFinishedPercentage(data.user.studentID, 'RT_Student_Portal', 0.8).pipe(tap((ans) => {this.IsLoggedService.updateIsInternship(ans.data); console.log('isStudentFinishedPercentage on - 0.8:', ans); }));
            const placement$ = this.serverApiService.isStudentFinishedPercentage(data.user.studentID, 'RT_Student_Portal', 0.9).pipe( tap((ans) => {this.IsLoggedService.updateIsPlacement(ans.data); console.log('isStudentFinishedPercentage on - 0.9:', ans); }));
            const graduation$ = this.serverApiService.isStudentFinishedPercentage(data.user.studentID, 'RT_Student_Portal', 1).pipe(tap((ans) => {this.IsLoggedService.updateIsGraduationCertificate(ans.data);console.log('isStudentFinishedPercentage on - 100%:', ans); }))
            return forkJoin([internship$,placement$,graduation$])
        }),
        catchError((error) => {
          console.log('fetchLoginNew in getLogin error', error);
          this.updateStateFail(error);
          return of(null); // Return an observable in case of error
        })
      ).subscribe();
    } else {
      console.log('/* geolocation IS NOT available */');
    }
  }
  closeModal(){
    this.loginDialog.nativeElement.close();
  }
  // const options = {
  //   enableHighAccuracy: true,
  //   timeout: 5000,
  //   maximumAge: 0,
  // };
  
  // success(pos) {
  //   const crd = pos.coords;
  
  //   console.log("Your current position is:");
  //   console.log(`Latitude : ${crd.latitude}`);
  //   console.log(`Longitude: ${crd.longitude}`);
  //   console.log(`More or less ${crd.accuracy} meters.`);
  // }
  
  // error(err) {
  //   console.warn(`ERROR(${err.code}): ${err.message}`);
  // }
  
  // navigator.geolocation.getCurrentPosition(success, error, options);
}
