import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatesManagerService {
  newSection:boolean;
  
  constructor() { }

  getNewSection(){
    return this.newSection
  }
  setSection(section:boolean){
      this.newSection = section
    // console.log('section', this.newSection)
  }
}
