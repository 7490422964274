import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder,FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServerApiService } from "app/services/server-api.service";
import { StudentDataService } from "app/services/students/student-data.service";
import SignaturePad from 'signature_pad';
import { PDFDocument } from 'pdf-lib';
import { Subscription } from "rxjs";
import { finalize }  from 'rxjs/operators';
import  {HttpEventType} from '@angular/common/http'
import { IsLoggedService } from 'app/services/data-share/is-logged.service';
import { environment } from "environments/environment";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-placement',
  templateUrl: './placement.component.html',
  styleUrls: ['./placement.component.css']
})
export class PlacementComponent implements OnInit {

  myForm: FormGroup;
  private signaturePad!: SignaturePad;
  isScheduleAppointment:boolean = false;
  @ViewChild('signaturePadCanvas') signaturePadCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('myDialog', { static: true }) myDialog!: ElementRef;
  @ViewChild('timeAndDateDialog', { static: true }) timeAndDateDialog!: ElementRef;
  @ViewChild('timeDialog', { static: true }) timeDialog!: ElementRef;
  @ViewChild('dateDialog', { static: true }) dateDialog!: ElementRef;
  isPlacement:boolean = false
  isPlacementSigned: boolean =false
  isPlacementSignedAlready: boolean =false
  studentId:any;
  studentURL: SafeResourceUrl|null = null;
  isCanvasClicked: boolean = false;
  isAgree: boolean = false
  isPlacementSection: boolean = false
  signatureDataURL: string| null = null;
  isGif:boolean = false;
  pdfBytesUpdated: any;
  signPlacementURLunsafe: any = ''
  signPlacementURL: any|null = null;
  studentEmail:any = null
  studentData:any = null

  constructor( private formBuilder: FormBuilder,private serverApiService: ServerApiService,private IsLoggedService: IsLoggedService,private StudentDataService: StudentDataService,private sanitizer: DomSanitizer ) { 
    this.myForm = new FormGroup({
      datePicker: new FormControl(null), // Initialize the date picker control with null or a default date value
      timePicker: new FormControl(null) // Initialize the date picker control with null or a default date value
    });
  }

  ngOnInit(): void {
    this.studentId = this.StudentDataService.getStudentInfo().studentID; 
    this.studentData = this.StudentDataService.getStudentInfo(); 
    console.log('this.studentData:',this.studentData);
    
    const unsafeUrl = `${environment.HOST}/downloads/placement.pdf/${this.studentId}`;
    this.studentURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
    this.IsLoggedService.isPlacement.subscribe(ans => {
      this.isPlacement = ans ? true:false;
    })


   this.serverApiService.postIsPlacementSigned({ studentID: this.studentId }).subscribe((ans)=>{
      console.log('postIsPlacementSigned:',ans);
      this.IsLoggedService.updateIsPlacementSignedAlready(ans.ans)
      this.isPlacementSignedAlready = ans.ans
  })
    // this.IsLoggedService.isPlacementSignedAlready.subscribe(ans => {
    //   this.isPlacementSignedAlready = ans
    //   console.log('isPlacementSignedAlready:', this.isPlacementSignedAlready, ans );
    // })
  }

  ngAfterViewInit(): void {
    if (this.signaturePadCanvas) {
      const canvasElement = this.signaturePadCanvas.nativeElement;
      this.signaturePad = new SignaturePad(canvasElement);
    }
}
  async saveAll(){
    if(this.isCanvasClicked){
      this.showPlacementSection()
    }
    this.saveSignature()
    this.isGif = true
    await this.loadAndSignPdf(`${environment.HOST}/downloads/placement.pdf/${this.studentId}`, this.signatureDataURL)
    this.sendPdfToServer(this.pdfBytesUpdated,`Placement_${this.studentId}`)
    this.signPlacementURLunsafe = `${environment.HOST}/admin/signedPlacement/${this.studentId}`
    console.log('this.signPlacementURLunsafe===>:',this.signPlacementURLunsafe)
    this.signPlacementURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.signPlacementURLunsafe);
    console.log('this.signPlacementURL==>:',this.signPlacementURL)
    const data ={
        to:  'hr.rted2000@gmail.com, omer.golan.rtg@gmail.com',
        from: 'portal-studets',
        subject : `Placement for - id: ${this.studentId}`,
        html : `this student:  ID:${this.studentId }, Name:${this.studentData.firstName},LastNAme:${this.studentData.familyName},Mobile Number: ${this.studentData.mobileNumber}, Email:${this.studentData.email} has signed on Placement - ${this.signPlacementURL}` , //, <a href="${safeUrl}">${safeUrl}</a>, <a href="${this.signNdaURL}">${this.signNdaURL}</a>` ,
        source : "RT_Student_Portal"
      }
    this.serverApiService.sendEmail(data).subscribe((ans)=>{
      console.log('after sendEmail to the server:',ans)
    })
    
  }
  saveSignature() {
    const dataURL = this.signaturePad.toDataURL();
    this.signatureDataURL = this.signaturePad.toDataURL();
   // console.log('ths saved sign:',dataURL);
  }


  async  loadAndSignPdf(url:any, signatureDataURL:any) {
    try {
      // Fetch the PDF from the URL
      const arrayBuffer = await fetch(url).then(res => res.arrayBuffer());
      // Load the PDF document
      const pdfDoc = await PDFDocument.load(arrayBuffer);
      // Embed the PNG signature
      const signatureImage = await pdfDoc.embedPng(signatureDataURL);
      // Ensure that there are at least 2 pages
      if (pdfDoc.getPageCount() < 2) {
        console.error('PDF does not have 2 pages');
        return;
      }
      // Add the signature to the 2th page
      const page = pdfDoc.getPage(1);
      const signatureWidth = 200;
      const signatureHeight = 90;
      const xPosition = 160;
      const yPosition = 490;
      page.drawImage(signatureImage, {
        x: xPosition,
        y: yPosition,
        width: signatureWidth,
        height: signatureHeight,
      });
      // Save the signed PDF
      const pdfBytesUpdated = await pdfDoc.save();
      this.pdfBytesUpdated = pdfBytesUpdated
    } catch (error) {
      console.error('Error processing PDF:', error);
    }
  }



  sendPdfToServer(pdfBytes: any, filename: string) {
    console.log('the pdfBytes:', pdfBytes)
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    console.log('the blob:', blob)
    // Create a FormData object
    const formData = new FormData();
    formData.append("studentId", this.studentId);
    // The 'file' in append is the key which will be used on the server side to retrieve the file
    formData.append('file', blob, filename);
    formData.append('destination', 'savedPDF');

    this.serverApiService.sendPlacementToServer(formData).subscribe((ans)=>{
      this.isGif = false
      console.log('after posting the sign to the server:',ans)
      this.isPlacementSigned = true
      const safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.signPlacementURL );// for security for the link- not working need to check.

      const data ={
        to:  'hr.rted2000@gmail.com, omer.golan.rtg@gmail.com',
        from: 'portal-studets',
        subject : `Placement for - id: ${this.studentId}`,
        html : `this student:${this.studentId } has signed on Placement - ${this.signPlacementURL}` , //, <a href="${safeUrl}">${safeUrl}</a>, <a href="${this.signNdaURL}">${this.signNdaURL}</a>` ,
        source : "RT_Student_Portal"
      }
        this.serverApiService.sendEmail(data).subscribe((ans)=>{
          console.log('after sendEmail to the server:',ans)
        })
      const dataForStudent ={
        to:  `${this.studentData.email}`,
        from: 'portal-studets',
        subject : `Asking for a meeting with Real Time Group HR`,
        html : ` Hello ${this.studentData.firstName}, Our recruiting team will contact you `,
        source : "RT_Student_Portal"
      }
        this.serverApiService.sendEmail(dataForStudent).subscribe((ans)=>{
          console.log('after sendEmail to the dataForStudent:',ans)
        })
    }, error => {
      console.error('EROR on sendPlacementtoServer',error); // Handle the error here
    })

  //  const dataTostudent={
    
  //  }

  }



  submitMeeting() {
    console.log('Form value-datePicker:',JSON.stringify(this.myForm.value.datePicker));
    console.log('Form value-timePicker:',JSON.stringify(this.myForm.value.timePicker));
    if(this.myForm.value.datePicker&&this.myForm.value.timePicker&&!this.isScheduleAppointment){
        this.serverApiService.postScheduleAppointment({date: JSON.stringify(this.myForm.value.datePicker), time : this.myForm.value.timePicker,studentData: this.studentData}).subscribe((ans)=>{
          this.isScheduleAppointment = true;
          this.myDialog.nativeElement.showModal();
          this.generateTicket()
          console.log('portScheduleAppointment',ans);  
      })
    } else if(!this.myForm.value.datePicker&&!this.myForm.value.timePicker){
      this.timeAndDateDialog.nativeElement.showModal();
    }else if(this.myForm.value.datePicker&&!this.myForm.value.timePicker){
      this.timeDialog.nativeElement.showModal();
    }else if(!this.myForm.value.datePicker&&this.myForm.value.timePicker){
      this.dateDialog.nativeElement.showModal();
    }
    
  }
  closeModal(){
    this.myDialog.nativeElement.close();
    this.timeAndDateDialog.nativeElement.close();
    this.timeDialog.nativeElement.close();
    this.dateDialog.nativeElement.close();
  }
  openTimePicker(timeInput: HTMLInputElement): void {
    timeInput.focus();
  }

  onCheckboxAgree(){
    this.isCanvasClicked = true
    this.isAgree = true
  }
  onCanvasClick(){
    this.isCanvasClicked  = true
  }
  showPlacementSection(){
    if(!this.isCanvasClicked){
      this.isPlacementSection = true
    }else{
      this.isPlacementSection = false
    }
  }
  hidePlacementSection(){
    this.isPlacementSection = false
  }
  generateTicket() {
    const ticketDetails = {
      owner: this.studentId,
      customer: this.studentId,
      txtReason: 'New HR meeting request',
      sectionOf: "hr_meeting",
    };
    this.serverApiService.newProblemTicket({ ticketDetails }).subscribe(
      (data: any) => {
        console.log('data');
      }
    );
  }
  clearSignature() {
    this.signaturePad.clear();
  }
}
