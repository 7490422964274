import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { ServerApiService } from "app/services/server-api.service";
import { registeredCourse } from "app/models/interfaces/registeredCourse/registeredCourse";
import {VideoListComponent} from '../../components/video-list/video-list.component'
import { Subject } from 'rxjs';
import { MySubject } from './mySubject';
import { IsLoggedService } from "app/services/data-share/is-logged.service";
import { User } from "app/models/interfaces/user/user";
import { StudentDataService } from 'app/services/students/student-data.service';


@Component({
  selector: 'app-video-page',
  templateUrl: './video-page.component.html',
  styleUrls: ['./video-page.component.css']
})
export class VideoPageComponent implements OnInit {
  @Output() registeredCourseEmiter: EventEmitter <registeredCourse[]>  = new EventEmitter<registeredCourse[]>();;
  @Output() mySubject: MySubject = new MySubject();

//  courses:registeredCourse[] = []
//  courses:Subject <registeredCourse[]>
  courses: Subject<registeredCourse[]> = new Subject<registeredCourse[]>();
  courses2 : registeredCourse[];
  studentId: string

  constructor( private ServerApiService: ServerApiService, private isLoggedService: IsLoggedService  ) { }

  ngOnInit(): void {
   

    this.isLoggedService.getData().subscribe((userData: User) => {
      if (userData) {
        console.log('userData:', userData);
        const studentID = userData.user.studentID; // Access the studentID from userData
        this.ServerApiService.getRegisteredCourses(studentID).subscribe((ans) => {
          console.log('  getRegisteredCourses =======>:',  ans)
          this.courses.next(ans)
          this.courses2  = ans
          console.log('  this.courses=======>:',  this.courses)
        });
      }
    });

  }

}



