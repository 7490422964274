import { Component, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { VideoSoftwareDownload,VideoSoftwareDownloadWin } from 'app/app.component';
@Component({
  selector: 'app-videosoft',
  templateUrl: './videosoft.component.html',
  styleUrls: ['./videosoft.component.css']
})
export class VideoSoftComponent implements OnInit {
  videoSoftwareDownload = VideoSoftwareDownload;
  VideoSoftwareDownloadWin=VideoSoftwareDownloadWin;
  constructor(private sanitizer:DomSanitizer) { }
  ngOnInit() {}
  onSubmit() {}
  getLink(){
    console.log(window.navigator.platform)
const platform = window.navigator.platform;
    const win = platform.match(/[Ww][Ii][Nn]/g);
    const lin = platform.match(/[Ll][Ii][Nn]/g);
    console.log(win,lin)
    if(win){return this.sanitizer.bypassSecurityTrustUrl(this.VideoSoftwareDownloadWin)}
    else if (lin){return this.sanitizer.bypassSecurityTrustUrl(this.videoSoftwareDownload)}
    return "platform not supported;"
  }
}
