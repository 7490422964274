import { Component, OnInit,ElementRef,ViewChild,Input } from '@angular/core';
import { Subject,Observable } from 'rxjs';
import { VideoSoftwareDownload, VideoSoftwareDownloadWin } from 'app/app.component';
import { registeredCourse } from "app/models/interfaces/registeredCourse/registeredCourse";
import {VideoPageComponent} from '../../pages/video-page/video-page.component'
import { ServerApiService } from "app/services/server-api.service";
import { VideoData } from "app/models/interfaces/videoList/videoList";
import { environment } from "environments/environment";
import { IsLoggedService } from "app/services/data-share/is-logged.service";
import { StudentDataService } from 'app/services/students/student-data.service';


@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.css']
})
export class VideoListComponent implements OnInit {

  @Input() test: registeredCourse[] 

  registeredCourses: registeredCourse[]|null = null
  @ViewChild('myVideo') myVideo!: ElementRef;

  videoSoftwareDownload = VideoSoftwareDownload;
  VideoSoftwareDownloadWin = VideoSoftwareDownloadWin;
  isDropdownOpen: boolean = false;
  courseDropdownStates: { [key: string]: boolean } = {};
  courseItems: VideoData[] = [];
  video_id: number = 0
  videoUrlSet: boolean = true;
  videoUrl: string;
  selectedCourseName: string | undefined;
  selectedVideoDate: string | undefined;
  currentlyShowingVideo: string | undefined;
  studentId: string
  
  constructor( private VideoPageComponent : VideoPageComponent, private serverApiService: ServerApiService, private studentDataService: StudentDataService ) { }

  ngOnInit(): void {
    this.studentId = this.studentDataService.getStudentInfo().studentID; 
    this.VideoPageComponent.courses.subscribe((ans)=>{
      this.registeredCourses = ans
      this.getVideoUrl(this.video_id);
      console.log("registeredCourses on video-list-cmp==> ",ans)
    })
  }

  

  getVideoUrl(video_id: number): void {
    console.log('video_id:',video_id);
    console.log('courseItems - video_id:',this.courseItems);
    this.videoUrl = `${environment.HOST}/student/unicko-play-video?video_id=${video_id}`;
    this.videoUrlSet = true;
    const studentData = { studentId : this.studentId, videoId: video_id}
    if(video_id){
      this.serverApiService.postVideoWatcherLog(studentData).subscribe(ans=>{
        console.log('postVideoWatcherLog on video-list',ans);
        
      })
    }
  }

  seekForward() {
    this.myVideo.nativeElement.currentTime += 30;
  }

  seekBackward() {
    this.myVideo.nativeElement.currentTime -= 10;
  }

  toggleDropdown(event: Event) {
    event.stopPropagation();
    this.isDropdownOpen = !this.isDropdownOpen;
  }


  toggleCourseDropdown(event: Event, course: registeredCourse) {
    event.stopPropagation();
    this.courseDropdownStates[course.courseName] = !this.courseDropdownStates[course.courseName];

    if (this.isCourseDropdownOpen(course)) {
      // Fetch items for the course from the server
      this.serverApiService.getVideoListForCourse(course.code.toString()).subscribe((videos) => {
        console.log(videos);
        this.courseItems[course.code.toString()] = videos.map(video => ({
          video_date: this.formatDate(video.video_date),
          video_duration: video.video_duration,
          video_url: video.video_url,
          video_id: video.video_id,
          exist: video.exist,
          courseName: course.courseName,
          __isOpen: false // Initialize __isOpen property as false
        }));

        // Join arrays based on video_date property
        const joinedArray = [];
        this.courseItems[course.code.toString()].forEach(video => {
          const existingArray = joinedArray.find(arr => arr[0]?.video_date === video.video_date);
          if (existingArray) {
            existingArray.push(video);
          } else {
            joinedArray.push([video]);
          }
        });

        this.courseItems[course.code.toString()] = joinedArray;
        console.log(this.courseItems[course.code.toString()]); // Log the joined array
      });
    }
    this.selectedCourseName = course.courseName;
  }



  isVideoArrayOpen(videoArray: any[]): boolean {
    return videoArray?.length > 1;
  }

  toggleVideoArray(videoArray: any[]): void {
    videoArray['__isOpen'] = !videoArray['__isOpen'];
    if (videoArray['__isOpen']) {
      videoArray.forEach(video => video['__show'] = true);
    } else {
      videoArray.forEach(video => video['__show'] = false);
    }
  }



  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day} ${year}`;
  }

  formatVideoDuration(duration: string): string {
    let formattedDuration = duration;
    if (formattedDuration.includes('hours')) {
      formattedDuration = formattedDuration.replace(' hours', 'h');
    } else if (formattedDuration.includes('hour')) {
      formattedDuration = formattedDuration.replace(' hour', 'h');
    }
    if (formattedDuration.includes('minutes')) {
      formattedDuration = formattedDuration.replace(' minutes', 'min');
    } else if (formattedDuration.includes('minute')) {
      formattedDuration = formattedDuration.replace(' minute', 'min');
    }
    return formattedDuration;
  }

  isCourseDropdownOpen(course: registeredCourse): boolean {
    return this.courseDropdownStates[course.courseName];
  }


  handleVideoClick(event: Event, video: VideoData) {
    event.stopPropagation();
    if(video.exist){
      this.getVideoUrl(video.video_id);
      console.log('exist?:',video.exist);
    }
    

    this.selectedVideoDate = video.video_date;
    this.currentlyShowingVideo = this.selectedCourseName + ' - ' +
      this.selectedVideoDate
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }
}
