import { Component, OnInit, Input } from "@angular/core";
import { cardCourse } from "app/models/classes/cardCourse";

@Component({
  selector: "app-course-card",
  templateUrl: "./course-card.component.html",
  styleUrls: ["./course-card.component.css"],
  providers: [],
})
export class CourseCardComponent implements OnInit {
  @Input() course: cardCourse;
  now = new Date(Date.now()).getTime();
  start;
  end;
  progress;
  constructor() {}

  ngOnInit(): void {
    this.start = this.getDates().start;
    this.end = this.getDates().end;
    this.progress = this.getProgress();
  }

  getDates() {
    const startDate = new Date(this.course.startDate);
    const endDate = new Date(this.course.endDate);
    console.log(startDate, endDate);
    return {
      start: startDate,
      end: endDate,
    };
  }
  getProgress() {
    console.log(this.course.startDate, this.course.endDate);
    let ans =
      (this.now - this.course.startDate) /
      (this.course.endDate - this.course.startDate);
    ans = ans > 1 ? 100 : ans * 100;
    ans = ans < 0 ? 0 : ans;
    console.log({ width: `${Math.round(ans)}%` });
    return { width: `${Math.round(ans)}%` };
  }

  getColorStatus() {
    const desc = () => {
      if (
        this.progress.width.slice(0, -1) < 100 &&
        this.progress.width.slice(0, -1) > 0
      ) {
        return 0;
      }
      if (this.progress.width.slice(0, -1) >= 100) {
        return 1;
      }
      return -1;
    };
    switch (desc()) {
      case 1:
        return "done-card";
      case 0:
        return "";
      case -1:
        return "yet-card";
      default:
        return "";
    }
  }
}
