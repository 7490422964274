import { PlatformLocation } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { switchMap, catchError, tap  } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { Router } from "@angular/router";
import { AuthService } from "app/services/auth/auth.service";
import { IsLoggedService } from "app/services/data-share/is-logged.service";
import { APPBASEHREFService } from "app/services/app-base-href.service";
import { ServerApiService } from "app/services/server-api.service";

declare const $: any;
  // const f = new IsLoggedService
  // const ans = f.getisInternship()
  // console.log('ans on sidebar1',ans)

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export let ROUTES: RouteInfo[] = []



@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  isUserLoggedIn: boolean = false;
  first_name: string = "";
  last_name: string = "";
  baseSrc:any;
  userData: any|null = null

  isInternship:boolean = false
  isInternshipPress:boolean = false
  isPlacement:boolean = false
  isPlacementPress:boolean = false
  isGraduationCertificate: boolean = false
  isGraduationCertificatePress: boolean = false

  userProfile: string = "Login";
  menuItems: RouteInfo[];
  isSupport: boolean = true;

  constructor(
    private router: Router,
    private IsLoggedService: IsLoggedService,
    private AuthService: AuthService, 
    private APPBASEHREFS :APPBASEHREFService,
    private platformLocation: PlatformLocation,
    private serverApiService: ServerApiService
  ) {}

//postIsPlacementSigned

  ngOnInit() {
    this.IsLoggedService.getData().subscribe((data)=>{
      this.userData = data; // Set the userData here
    })

    this.baseSrc = this.APPBASEHREFS.getBaseHref(this.platformLocation)
    this.IsLoggedService.isInternship.subscribe(ans => {
      this.isInternship = ans ? true : false
      this.IsLoggedService.isPlacement.subscribe(ans => {
        this.isPlacement = ans ? true : false
        this.IsLoggedService.isGraduationCertificate.subscribe(ans => {
          this.isGraduationCertificate = ans ? true:false;
          this.menuItems = this.getRoutes();
        })
      })
    });
    this.IsLoggedService.isUserLoggedIn.subscribe((userLoggedIn: boolean) => {
      if (userLoggedIn) {
        this.isUserLoggedIn = userLoggedIn;
        this.first_name = sessionStorage.getItem("first_name");
        this.last_name = sessionStorage.getItem("last_name");
      }
    });
    this.IsLoggedService.userProfile.subscribe((userProfile: string) => {
      console.log('SidebarComponent - this.userProfile=>',this.userProfile);
      console.log('SidebarComponent - userProfile=>',userProfile);
      
      this.userProfile = userProfile;
    });
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }
  startLogout() {
    this.IsLoggedService.setUserLoggedIn(false);
    this.isUserLoggedIn = false;
    sessionStorage.clear();
    this.AuthService.logout();
    // this.router.navigate(['/']);
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }
  getRoutes(): RouteInfo[] {
    return  ROUTES = [
      //  { path: '/notifications', title: 'notifications',  icon: 'dashboard', class: 'Teacher' },
      { path: "/", title: "Login", icon: "dashboard", class: "Login" },
      {
        path: "/user-profile",
        title: "Student Profile",
        icon: "person",
        class: "Student",
      },
      {
        path: "/table-list",
        title: "Courses List",
        icon: "list_alt",
        class: "Student",
      },
      {
        path: "/teacher-list",
        title: "Courses List",
        icon: "list_alt",
        class: "Teacher",
      },
      { path: "/solaredge-course-list", title: "SolarEdge Course List", icon: "list_alt", class: "Guest" },

      // { path: "/outsource-course-list", title: "Outsource Course-list", icon: "list_alt", class: "Guest" },

      { path: "/fullcal", title: "Calendar", icon: "event", class: "Student" },
      // { path: "/outsource-calendar", title: "Outsource Calendar", icon: "event", class: "Guest" },
      // { path: "/outsource-students-data", title: "Students Data", icon: "event", class: "Guest" },
      { path: "/fullcalteach", title: "Calendar", icon: "event", class: "Teacher" },
      { path: "/videosoft", title: "VOD", icon: "bubble_chart", class: "Student" },
      //  { path: '/message', title: 'Messaging',  icon:'notifications', class: 'Teacher' },
      //  { path: '/ticket', title: 'Tickets',  icon:'notifications', class: 'Teacher' },
      {
        path: "/administration",
        title: "Administration",
        icon: "perm_identity",
        class: "Student",
      },
      // { path: '/courses-container', title: 'Courses', icon: 'list_alt', class: 'Student' },
      // { path: '/courses-container', title: 'Courses', icon: 'list_alt', class: 'Teacher' },
      {
        path: "/students-data",
        title: "Students Data",
        icon: "list_alt",
        class: "Teacher",
      },
      {
      path: "/internship",
      title: "Internship",
      icon: "perm_identity",
      class: "Student",
      },
      {
      path: "/placement",
      title: "Placement",
      icon: "perm_identity",
      class: "Student",
      },
      {
      path: "/graduation_certificate",
      title: "Graduation Certificate",
      icon: "perm_identity",
      class: "Student",
      },
      // {
      //   path: "/recruitment",
      //   title: "Recruitment",   // in development for this version , next verion - it will be ready
      //   icon: "perm_identity",
      //   class: "Student",
      // }
    ];
  }

  onChoose(tab: string){
    this.isInternshipPress = (tab==='/internship') ? true : false
    this.isPlacementPress = (tab==='/placement') ? true : false
    this.isGraduationCertificatePress = (tab==='/graduation_certificate') ? true : false

  }
}
