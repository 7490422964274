/* Angular imports. */
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FullCalendarModule } from "@fullcalendar/angular";

/* Import the widget module that holds the angular material module libraries. */
import { WidgetModule } from "app/modules/widget/widget.module";
/* Import the shared module that holds the shared components, directive and pipes. */
import { SharedModule } from "app/modules/shared/shared.module";

/* Auth guard. */
import { AuthGuard } from "app/auth.guard";

/* Teachers component pages. */
import { TeacherListComponent } from "./components/teacher-list/teacher-list.component";
import { FullCalTeachComponent } from "./components/fullcalteach/fullcalteach.component";
import { LessonsTabComponent } from "../misc/components/lessons-tab/lessons-tab.component";
import { QuillModule } from "ngx-quill";
import { MiscModule } from "../misc/misc.module";

const routes: Routes = [
  {
    path: "",
    component: TeacherListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "teacher-list/:course/:cycle/:lesson",
    component: LessonsTabComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fullcalteach",
    component: FullCalTeachComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [FullCalTeachComponent, TeacherListComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    WidgetModule,
    MiscModule,
    QuillModule,
    FullCalendarModule,
  ],
  exports: [RouterModule, FullCalTeachComponent, TeacherListComponent],
})
export class TeachersModule {}
