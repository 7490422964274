import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ServerApiService } from "app/services/server-api.service";
import { IsLoggedService } from "app/services/data-share/is-logged.service";
import { User } from "app/models/interfaces/user/user";
import { MatCheckboxChange } from '@angular/material/checkbox';



export interface Task {
  name: string;
  completed: boolean;
  color: ThemePalette;
  subtasks?: Task[];
}

export interface Preparation{
  cv: boolean,
  personal: boolean,
  professional: boolean
}

@Component({
  selector: 'app-recruitment',
  templateUrl: './recruitment.component.html',
  styleUrls: ['./recruitment.component.css'],
  
})
export class RecruitmentComponent implements OnInit {
  @ViewChild('myDialogCvPreparation', { static: true }) myDialogCvPreparation!: ElementRef;

  constructor( private ServerApiService: ServerApiService,  private IsLoggedService: IsLoggedService) { }
  user: User
  preparation: Preparation = {
    cv: false,
    personal: false,
    professional: false
  }

  task: Task = {
    name: 'Indeterminate',
    completed: false,
    color: 'primary',
    subtasks: [
      {name: 'Primary', completed: false, color: 'primary'},
      {name: 'Accent', completed: false, color: 'accent'},
      {name: 'Warn', completed: false, color: 'warn'},
    ],
  };

  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => (t.completed = completed));
  }
  ngOnInit(): void {
    this.IsLoggedService.getData().subscribe((data: User) => {
      this.user = data;

    })
  }

  onChooseCV(event: MatCheckboxChange){
    this.preparation.cv = event.checked
    this.myDialogCvPreparation.nativeElement.showModal();
    console.log('onCheckboxChangeCV',event.checked)
  }

  closeMyDialogCvPreparation(){
    this.myDialogCvPreparation.nativeElement.close();
  }
  onChoosePersonal(event: MatCheckboxChange){
    this.preparation.personal = event.checked
    console.log('onCheckboxChangePersonal',event.checked)
  }
  onChooseProfessional(event: MatCheckboxChange){
    this.preparation.professional = event.checked
    console.log('onCheckboxChangeProfessional',event.checked)
  }

  sendPreparation(){
  const cv = this.preparation.cv ?  'cv,': ''
  const personal = this.preparation.personal ?  'personal,': ''
  const professional = this.preparation.professional ?  'professional': ''
  const finalPreperation  = cv + ' ' + personal + ' ' + professional

    const data = {
      "to":  'omer.golan.rtg@gmail.com', 
      "from":'student portal', 
      "subject": 'request for Preparation',
      "html": `the student: ${this.user.user.firstName} - ${this.user.user.familyName }, id: ${this.user.user.studentID} is asking for Preparation in ${finalPreperation} `,
      "source" : "RT_Student_Portal"
    }
    this.ServerApiService.sendEmail(data).subscribe(
      (ans) => {
        console.log('sent Email',ans)
    
      })
  }
}
