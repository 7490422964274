import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-choose-tech-isues',
  templateUrl: './choose-tech-isues.component.html',
  styleUrls: ['./choose-tech-isues.component.css']
})
export class ChooseTechIsuesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
