import { Injectable } from "@angular/core";
import { TicketType } from "app/models/classes/ticketType";
import { IsLoggedService } from "app/services/data-share/is-logged.service";
@Injectable({
  providedIn: "root",
})
export class TicketsService {
  section: string = null;
  isGraduation:boolean = false
  ticket_type_1 = [
    new TicketType("Technical", "tech", "some description"),
    new TicketType("Administration", "admin", "some description"),
    // new TicketType('Education', 'edu', 'some description'),
    new TicketType("Documents", "docs", "some description"),
  ];
  ticket_type_2: { tech: TicketType[]; admin: TicketType[]; docs: TicketType[]}
  constructor( private IsLoggedService: IsLoggedService,) {
        this.IsLoggedService.isGraduationCertificate.subscribe(ans => {
      this.isGraduation = ans ? true : false
    })
    if( this.isGraduation){
      this.ticket_type_2 = {
        tech: [
          new TicketType("V.O.D", "vod", "/administration/vod"),
          new TicketType("Student portal", "portal", "/administration/portal"),
          new TicketType("Other", "other", "/administration/other"),
        ],
        admin: [
          new TicketType("Exams", "exam", "/administration/exam"),
          new TicketType("Courses", "course", "/administration/course"),
          new TicketType("Toutor", "toutor", "/administration/ovo"),
          new TicketType("Intership", "intership", "/administration/internship"),
        ],
        docs: [
          new TicketType("Mark sheet", "marks", "/administration/report-card"),
          new TicketType("Student certificate","stud-cert", "/administration/cert"),
          new TicketType("Graduation Certificate", "graduation-certificate", "/administration/graduation-certificate")
          // new TicketType("NDA", "nda", "/administration/nda"),
        ],
      };
    }else{
      this.ticket_type_2 = {
        tech: [
          new TicketType("V.O.D", "vod", "/administration/vod"),
          new TicketType("Student portal", "portal", "/administration/portal"),
          new TicketType("Other", "other", "/administration/other"),
        ],
        admin: [
          new TicketType("Exams", "exam", "/administration/exam"),
          new TicketType("Courses", "course", "/administration/course"),
          new TicketType("Toutor", "toutor", "/administration/ovo"),
          new TicketType("Intership", "intership", "/administration/internship"),
        ],
        docs: [
          new TicketType("Mark sheet", "marks", "/administration/report-card"),
          new TicketType("Student certificate","stud-cert", "/administration/cert"),
          // new TicketType("NDA", "nda", "/administration/nda"),
        ],
      };
    }
  }
  get activeSection() {
    return this.section;
  }
  // ngOnInit() {

  // }

  


  set activeSection(section: string) {
    this.section = section;
  }
}
