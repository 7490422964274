import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ServerApiService } from "app/services/server-api.service";
import { StudentDataService } from "app/services/students/student-data.service";

@Component({
  selector: "app-one-on-one-session",
  templateUrl: "./one-on-one-session.component.html",
  styleUrls: ["./one-on-one-session.component.css"],
})
export class OneOnOneSessionComponent implements OnInit {
  txtReason: string;
  studentId: string;
  displayStyle = "none";
  colorSubmit =  "black";
  colorComment = "rgb(44 217 44)"

  constructor(
    private ServerApiService: ServerApiService,
    private location: Location,
    private StudentDataService: StudentDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.studentId = this.StudentDataService.getStudentInfo().studentID;
  }

  generateTicket() {
    const ticketDetails = {
      owner: this.studentId,
      customer: this.studentId,
      txtReason: this.txtReason,
      sectionOf: "one_on_one",
    };
    this.ServerApiService.newProblemTicket({ ticketDetails }).subscribe(
      (data: any) => {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = "reload";
        this.router.navigate(["/administration"]);
      }
    );
  }
  goBack() {
    this.location.back();
  }
  openCommentModal(comment=null){
    // this.commentStudent = comment
     this.displayStyle = "block";
   }
   saveComment(){
     console.log('close comment modal', this.txtReason)
     this.displayStyle = "none";
     this.colorSubmit = "rgb(44 217 44)"
     this.colorComment = "black"
   
   //  this.commentStudent = null
   }
   cancelComment(){
     console.log('close comment modal')
     this.displayStyle = "none";
     this.txtReason = null;
     this.colorSubmit = "black"
     this.colorComment = "rgb(44 217 44)"
   }
}
