import { Component, OnInit } from "@angular/core";
import { TeacherDataService } from "app/services/teachers/teacher-data.service";
import { StudentDataService } from "app/services/students/student-data.service";
import { IsLoggedService } from "app/services/data-share/is-logged.service";

import { Course } from "app/models/interfaces/course/course";
import { TeacherCalendar } from "app/models/interfaces/teacher/teacherCalendar";
import { TeacherCycles } from "app/models/interfaces/cycle/cycleTeacher";
import { StudentCalendar } from "app/models/interfaces/student/studentCalendar";
import { cardCourse } from "app/models/classes/cardCourse";

@Component({
  selector: "app-courses-container",
  templateUrl: "./courses-container.component.html",
  styleUrls: ["./courses-container.component.css"],
  providers: [],
})
export class CoursesContainerComponent implements OnInit {
  userProfile: String;
  coursesStudent: Course[];
  coursesTeacher: TeacherCycles[];
  calendarTeacher: TeacherCalendar[];
  calendarStudent: StudentCalendar[];
  target: cardCourse[];
  isLoaded: boolean;
  constructor(
    private TeacherDataService: TeacherDataService,
    private StudentDataService: StudentDataService,
    private IsLoggedService: IsLoggedService
  ) {}

  ngOnInit(): void {
    this.IsLoggedService.userProfile.subscribe((userProfile) => {
      this.userProfile = userProfile;
      if (userProfile === "Teacher") {
        this.coursesTeacher = this.TeacherDataService.getTeacherCourse();
        this.calendarTeacher = this.TeacherDataService.getTeacherCalendar();
        console.log(this.coursesTeacher, this.calendarTeacher);
        this.isLoaded = true;
        this.fillTarget(this.coursesTeacher, this.calendarTeacher);
      }
      if (userProfile === "Student") {
        this.coursesStudent = this.StudentDataService.getCourses();
        this.calendarStudent = this.StudentDataService.getCalendar();
        this.isLoaded = true;
        this.fillTarget(this.coursesStudent, this.calendarStudent);
      }
    });
  }

  fillTarget(courses, calendar) {
    console.log(courses, calendar);
    const target: cardCourse[] = [];
    if (courses && calendar) {
      courses.forEach((course) => {
        const courseEvents = calendar.filter((event) => {
          return (
            event.courseName === course.courseName &&
            event.cyclecode === (course.cycle || course.code)
          );
        });
        console.log(courseEvents);
        let start =
          courseEvents.length > 0
            ? new Date(courseEvents[0].sessionDate).getTime()
            : null;
        let end =
          courseEvents.length > 0
            ? new Date(
                courseEvents[courseEvents.length - 1].sessionDate
              ).getTime()
            : null;
        target.push({
          courseName: course.courseName,
          teach_name: course.teach_name,
          startDate: start,
          endDate: end,
          url: Number(course.url || course.url_conference),
          icon: "url_to_icon",
        });
      });
      this.coursesTeacher = courses;
      this.calendarTeacher = calendar;
      this.target = target;
      console.log(this.target);
    }
  }
}
