import { Component, OnInit, ViewChild, ElementRef,AfterViewInit   } from '@angular/core';
import SignaturePad from 'signature_pad';
import { PDFDocument } from 'pdf-lib';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { StudentDataService } from "app/services/students/student-data.service";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from "environments/environment";
import { ServerApiService } from "app/services/server-api.service";
import { IsLoggedService } from "app/services/data-share/is-logged.service";
import { myTicket } from "app/models/interfaces/ticket/ticket";


@Component({
  selector: 'app-internship',
  templateUrl: './internship.component.html',
  styleUrls: ['./internship.component.css']
})
export class InternshipComponent implements OnInit {
  @ViewChild('signaturePadCanvas') signaturePadCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('pdfViewer') pdfViewer: ElementRef;


  nda_section: boolean = false; 
  private signaturePad!: SignaturePad;
  private pdfBytes: Uint8Array | null = null;
  pdfSrc: string | ArrayBuffer | null = null; // For ng2-pdf-viewer
  signatureDataURL: string| null = null;
  pdfBytesUpdated: any;
  canvasClicked: boolean = false;
  studentId:any;
  serverPORTurl: string = ''
  signNdaURLunsafe: any = ''
  signNdaURL: any|null = null;
  studentURL: SafeResourceUrl|null = null;
  isNdaSigned: boolean = false
  isNdaSignedAlready: boolean = false
  isGif: boolean = false
  txtReason:string = ''
  // studentURL:string|null = null


  Agree: boolean = false
  panelOpenState:boolean  = false;
  panelOpenState2:boolean  = false;
  panelOpenState3:boolean  = false;
  panelOpenState4:boolean  = false;
  panelOpenState5:boolean  = false;
  isInternship:boolean = false
  constructor( private StudentDataService: StudentDataService, private sanitizer: DomSanitizer, private serverApiService: ServerApiService, private IsLoggedService: IsLoggedService) {

   }

  ngOnInit(): void {
    this.studentId = this.StudentDataService.getStudentInfo().studentID; 
    const unsafeUrl = `${environment.HOST}/downloads/internship-NDA.pdf/${this.studentId}`;
    this.studentURL = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
    this.serverApiService.postIsNdaSigned({studentID: this.studentId }).subscribe((ans)=>{
      this.IsLoggedService.updateIsNdaSignedAlready(ans.ans)
      this.isNdaSignedAlready = ans.ans
      console.log('postIsNdaSigned============>',ans)
    })
    this.IsLoggedService.isInternship.subscribe(ans => {
      this.isInternship = ans
      console.log('isInternship:',this.isInternship);

    })
    // this.IsLoggedService.isNdaSignedAlready.subscribe(ans => {
    //   this.isNdaSignedAlready = ans
    //   console.log('isNdaSignedAlready:', this.isNdaSignedAlready );
    // })
    
  }
 ngAfterViewInit(): void {
    // const canvasElement = this.signaturePadCanvas.nativeElement;
    // this.signaturePad = new SignaturePad(canvasElement);
    if (this.signaturePadCanvas) {
      const canvasElement = this.signaturePadCanvas.nativeElement;
      this.signaturePad = new SignaturePad(canvasElement);
    }

    // suppose to make it to be able to show btn for confirmation only after scrolling down the document but its not working
    // this.signaturePadCanvas.nativeElement.style.display = 'none';
    // const iframe: HTMLIFrameElement = this.pdfViewer.nativeElement;
    // iframe.addEventListener('load', () => {
    //   try {
    //     const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
    //     iframeDocument.addEventListener('scroll', () => {
    //       // if (iframeDocument.documentElement.scrollTop + iframe.contentWindow.innerHeight >= iframeDocument.documentElement.scrollHeight) {
    //       //   this.signaturePadCanvas.nativeElement.style.display = 'block';
    //       // }
    //       console.log('yes')
    //     });
    //   } catch (e) {
    //     console.error("Cannot access iframe content:", e);
    //   }
    // });
  }

  
  clearSignature() {
    this.signaturePad.clear();
  }

  saveSignature() {
    const dataURL = this.signaturePad.toDataURL();
    this.signatureDataURL = this.signaturePad.toDataURL();
   // console.log('ths saved sign:',dataURL);
  }
  async onFileSelected(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        this.pdfBytes = new Uint8Array(e.target?.result as ArrayBuffer);
      };
      reader.readAsArrayBuffer(file);
    }
  }


async  loadAndSignPdf(url:any, signatureDataURL:any) {
  try {
    // Fetch the PDF from the URL
    const arrayBuffer = await fetch(url).then(res => res.arrayBuffer());
    // Load the PDF document
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    // Embed the PNG signature
    const signatureImage = await pdfDoc.embedPng(signatureDataURL);
    // Ensure that there are at least 5 pages
    if (pdfDoc.getPageCount() < 5) {
      console.error('PDF does not have 5 pages');
      return;
    }
    // Add the signature to the 5th page
    const page = pdfDoc.getPage(4);
    const signatureWidth = 200;
    const signatureHeight = 100;
    const xPosition = 160;
    const yPosition = 465;
    page.drawImage(signatureImage, {
      x: xPosition,
      y: yPosition,
      width: signatureWidth,
      height: signatureHeight,
    });
    // Save the signed PDF
    const pdfBytesUpdated = await pdfDoc.save();
    this.pdfBytesUpdated = pdfBytesUpdated
  } catch (error) {
    console.error('Error processing PDF:', error);
  }
}

  // send PDF to server 
  sendPdfToServer(pdfBytes: any, filename: string) {
    console.log('the pdfBytes:', pdfBytes)
    this.isGif = true
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    console.log('the blob:', blob)
  // Create a FormData object
    const formData = new FormData();
    formData.append("studentId", this.studentId);
    // The 'file' in append is the key which will be used on the server side to retrieve the file
    formData.append('file', blob, filename);
    formData.append('destination', 'savedPDF');

    this.serverApiService.sendNDAtoServer(formData).subscribe((ans)=>{
    this.isGif = false
    console.log('after posting the sign to the server:',ans)
      this.isNdaSigned = true
      const safeUrl = this.sanitizer.bypassSecurityTrustUrl(this.signNdaURL );// for security for the link- not working need to check.

      const data ={
        to:  'dima@rt-ed.co.il, info@rt-ed.co.il, bennyc1313@gmail.com, omer.golan.rtg@gmail.com',
        from: 'portal-studets',
        subject : `NDA for - id: ${this.studentId}`,
        html : `this student:${this.studentId } has signed on Internship - ${this.signNdaURL}` , //, <a href="${safeUrl}">${safeUrl}</a>, <a href="${this.signNdaURL}">${this.signNdaURL}</a>` ,
        source : "RT_Student_Portal"
      }
        this.serverApiService.sendEmail(data).subscribe((ans)=>{
          console.log('after sendEmail to the server:',ans)
        })
  }, error => {
    console.error('EROR on sendNDAtoServer',error); // Handle the error here
  })
  }


async saveAll(){
  if(this.canvasClicked){
    this.showNdaSection()
  }
  this.saveSignature()
  await this.loadAndSignPdf(`${environment.HOST}/downloads/internship-NDA.pdf/${this.studentId}`, this.signatureDataURL)
  this.sendPdfToServer(this.pdfBytesUpdated,`NDA_${this.studentId}`)
  this.signNdaURLunsafe = `${environment.HOST}/admin/signedNda/${this.studentId}`
  console.log('this.signNdaURLunsafe===>:',this.signNdaURLunsafe)
  this.signNdaURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.signNdaURLunsafe);
  console.log('this.signNdaURL==>:',this.signNdaURL)
  this.generateTicket()
  
}
showNdaSection(){
  if(!this.canvasClicked){
    this.nda_section = true
  }else{
    this.nda_section = false
  }
}
hideNdaSection(){
  this.nda_section = false
}

onCanvasClick(){
  this.canvasClicked  = true
}
onCheckboxAgree(){
    this.canvasClicked = true
    this.Agree = true
}


generateTicket() {
  const ticketDetails = {
    owner: this.studentId,
    customer: this.studentId,
    txtReason: 'New Internship request',
    sectionOf: "internship",
  };
  this.serverApiService.newProblemTicket({ ticketDetails }).subscribe(
    (data: any) => {
      console.log('data');
    }
  );
}

}
