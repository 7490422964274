import { Component, OnInit,ElementRef,ViewChild  } from '@angular/core';
import { ServerApiService } from 'app/services/server-api.service';
import { StudentDataService } from 'app/services/students/student-data.service';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";


@Component({
  selector: 'app-student-graduation-certificate',
  templateUrl: './student-graduation-certificate.component.html',
  styleUrls: ['./student-graduation-certificate.component.css']
})
export class StudentGraduationCertificateComponent implements OnInit {
  @ViewChild('myDialog', { static: true }) myDialog!: ElementRef;
  studentCert$: Observable<any>|null = null;
  theurl:Blob|null = null
  documentUrl: any = null
  isStudentCertificate:boolean = false;

  constructor(  private StudentDataService: StudentDataService, private ServerApiService: ServerApiService, private router: Router) { }

  ngOnInit(): void {
    const data = {
      // 'course_name': (this.fullPath) ? 'pathname' : this.selectedCourse.courseName,
      id: this.StudentDataService.getStudentInfo().studentID,
      fname: this.StudentDataService.getStudentInfo().firstName,
      lname: this.StudentDataService.getStudentInfo().familyName,
    };
        // 
        this.ServerApiService.buildStudentGraduationCertificate(data, "graduation_certificate").subscribe(
          (data: any) => {
            console.log('student-cert.component,buildStudentCert,  data ============>:',data)
            if (data.status) {
              this.studentCert$ = this.ServerApiService.getDocument(
                "graduation_certificate.pdf",
                `secretary_docs/issued/${
                  this.StudentDataService.getStudentInfo().studentID
                }`
              );
            }
          }
          );
  }
  newCretTicket(theurl) {
    this.documentUrl = URL.createObjectURL(
      new Blob([theurl], { type: "application/pdf" })
    );
    const ticketDetails = {
      owner: this.StudentDataService.getStudentInfo().studentID,
      customer: this.StudentDataService.getStudentInfo().studentID,
      sectionOf: "cert",
    };
    // this.ServerApiService.newDocTicket({ ticketDetails }).subscribe(
    //   (data: any) => {
    //     // this.ServerApiService.newAction(actions, data.data._id)
    //     //   .subscribe(data => {
    //     window.open(this.documentUrl);
    //     this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //     this.router.onSameUrlNavigation = "reload";
    //     this.router.navigate(["/administration"]);
    //   }
    // );
  }
  createStudentCertificate(){
    this.isStudentCertificate = true
  }

}
