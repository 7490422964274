import { Injectable, EventEmitter, Output } from "@angular/core";
import { forkJoin } from "rxjs";

/* Service imports. */
import { ServerApiService } from "app/services/server-api.service";
import { IsLoggedService } from "app/services/data-share/is-logged.service";

/* model imports. */
import { Course } from "app/models/interfaces/course/course";
import { linkStatus } from "app/models/interfaces/linkStatus";
import { StudentCalendar } from "app/models/interfaces/student/studentCalendar";
import { VisitsTotal } from "app/models/interfaces/visits/visitsTotal";
import { User } from "app/models/interfaces/user/user";

@Injectable({ providedIn: "root" })
export class StudentDataService {
  user: User;
  partic_url: any;
  status: boolean;
  courses: Course[];
  clendarEvents: StudentCalendar[];
  attendenceTotal: VisitsTotal[];
  @Output() studentloadend = new EventEmitter();
  constructor(
    private ServerApiService: ServerApiService,
    private IsLoggedService: IsLoggedService
  )
   {
    this.IsLoggedService.getData().subscribe((data: User) => {
      this.user = data;
      if (this.user.user.role === 1) {
        forkJoin([
          this.ServerApiService.fetchCoursesSessions(this.user.user.studentID),
          this.ServerApiService.fetchCalendar(this.user.user.studentID),
          this.ServerApiService.fetchAttendanceDataTotal(
            this.user.user.studentID
          ),
        ]).subscribe(
          ([courses, calendar, attendenceTotal]: [
            Course[],
            StudentCalendar[],
            VisitsTotal[]
          ]) => {
            this.verifyLinks(courses);
            this.courses = courses;
            this.clendarEvents = calendar;
            this.attendenceTotal = attendenceTotal;
            this.studentloadend.emit();
          }
        );
      }
    });
  }

  verifyLinks(courses: Course[]) {
    courses.map((course) => {
      if (course.url) {
        this.ServerApiService.checkLink(course.url.toString()).subscribe(
          (ans: linkStatus) => {
            if (!ans.status) {
              course.url = null;
            }
          }
        );
      }
    });
  }

  checkLink(meeting: number) {
    this.ServerApiService.checkLink(meeting.toString());
  }

  giveMeParticipantUrl(
    meeting: number,
    fname: string,
    lname: string,
    stud_id: string
  ) {
    this.partic_url = this.ServerApiService.giveMeParticipLink(meeting, {
      fname: fname,
      lname: lname,
      stud_id: stud_id,
    });
    this.partic_url.subscribe(
      (data) => {
        try {
          window.open(data, "_blank");
        } catch (e) {
          window.open(data, "_self");
        }
      },
      (error) => {
        console.log("err!!", error);
      }
    );
  }

  getCourses() {
    return this.courses;
  }

  getCalendar() {
    return this.clendarEvents;
  }
  getStudentInfo() {
    return this.user.user;
  }
  getAttendenceTotal() {
    return this.attendenceTotal;
  }
 

  // async getQuestionnaire(){
  //   return  this.ServerApiService.getQuestionnaire()
  // }
}
