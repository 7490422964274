import { Component, OnInit } from "@angular/core";
import { ServerApiService } from "app/services/server-api.service";
@Component({
  selector: "app-administration",
  templateUrl: "./administration.component.html",
  styleUrls: ["./administration.component.css"],
  providers: [ServerApiService],
})
export class AdministrationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
