import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ServerApiService } from "app/services/server-api.service";
import { StudentDataService } from "app/services/students/student-data.service";

@Component({
  selector: "app-request-training-session",
  templateUrl: "./request-training-session.component.html",
  styleUrls: ["./request-training-session.component.css"],
})
export class RequestTrainingSessionComponent implements OnInit {
  txtReason: string;
  studentId: string;
  studentCourses: any;
  selectedCourse: any;
  errorMesssage: string;
  constructor(
    private ServerApiService: ServerApiService,
    private location: Location,
    private StudentDataService: StudentDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.studentId = this.StudentDataService.getStudentInfo().studentID;
    this.studentCourses = this.StudentDataService.getCourses();
    this.errorMesssage = null;
  }

  generateTicket() {
    if (!this.txtReason || !this.selectedCourse) {
      this.errorMesssage = "please fill all of the fields above";
      return;
    } else {
      const ticketDetails = {
        owner: this.studentId,
        customer: this.studentId,
        txtReason: {
          txtReason: this.txtReason,
          selectedCourse: this.selectedCourse,
        },
        sectionOf: "training_session",
      };
      this.ServerApiService.newProblemTicket({ ticketDetails }).subscribe(
        (data: any) => {
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = "reload";
          this.router.navigate(["/administration"]);
        }
      );
    }
  }

  setSelectedCourse(course) {
    this.errorMesssage = null;
    this.selectedCourse = course;
  }
  goBack() {
    this.location.back();
  }
}
