/* Angular imports. */
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

/* Import the widget module that holds the angular material module libraries. */
import { WidgetModule } from "app/modules/widget/widget.module";
/* Import the shared module that holds the shared components, directive and pipes. */
import { SharedModule } from "app/modules/shared/shared.module";
import { MatRadioModule } from '@angular/material/radio';

/* Misc component pages. */
import { FileHolderComponent } from "./components/file-holder/file-holder.component";
import { VideoSoftComponent } from "./components/videosoft/videosoft.component";
import { CoursesContainerComponent } from "./components/courses-container/courses-container.component";
import { QuillModule } from "ngx-quill";
import { LessonsTabComponent } from "./components/lessons-tab/lessons-tab.component";
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
  declarations: [
    CoursesContainerComponent,
    FileHolderComponent,
    LessonsTabComponent,
    VideoSoftComponent,

  ],
  imports: [CommonModule, SharedModule, WidgetModule, QuillModule,MatRadioModule,MatSnackBarModule],
  exports: [
    CoursesContainerComponent,
    FileHolderComponent,
    LessonsTabComponent,
    VideoSoftComponent,
    

    
  ],
})
export class MiscModule {}
