import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs/Observable";

import { ServerApiService } from "app/services/server-api.service";
import { StudentDataService } from "app/services/students/student-data.service";

import { SignaturePad } from "ngx-signaturepad/signature-pad";

@Component({
  selector: "app-nda-obligation",
  templateUrl: "./nda-obligation.component.html",
  styleUrls: ["./nda-obligation.component.css"],
})
export class NdaObligationComponent implements OnInit {
  @ViewChild("signatureRef") signaturePad: SignaturePad;
  @ViewChild("signatureContainer") signatureContainer: ElementRef;
  signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    minWidth: 1,
    canvasWidth: 420,
    canvasHeight: 150,
    backgroundColor: "rgb(255,255,255)",
  };
  signature: any = null;

  selectedCourse;
  fullPath: boolean;
  ndaStatus$: Observable<any>;
  state$: any;
  ticket_id: string;

  constructor(
    private ServerApiService: ServerApiService,
    private StudentDataService: StudentDataService,
    private router: Router,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.ndaStatus$ = this.ServerApiService.getDocument(
      "Internship-NDA.pdf",
      "secretary_docs/tamplate"
    );
    this.ticket_id = window.history.state.ticket_id;
  }

  drawComplete(): void {
    this.signature = this.signaturePad.toDataURL();
    console.log(this.signature);
  }
  clearCanvas() {
    this.signaturePad.clear();
  }
  size() {
    this.signaturePad.set(
      "canvasWidth",
      this.signatureContainer.nativeElement.clientWidth
    );
    this.signaturePad.set(
      "canvasHeight",
      this.signatureContainer.nativeElement.clientHeight
    );
  }
  buildNDAForm() {
    const data = {
      id: this.StudentDataService.getStudentInfo().studentID,
      name: this.StudentDataService.getStudentInfo().firstName,
      lname: this.StudentDataService.getStudentInfo().familyName,
      address: this.StudentDataService.getStudentInfo().address,
      country: "Israel",
      signature: this.signature,
    };
    this.ndaStatus$ = null;
    this.ServerApiService.buildNdaForm(data, "Internship-NDA").subscribe(
      (data: any) => {
        if (data.status) {
          this.ndaStatus$ = this.ServerApiService.getDocument(
            "Internship-NDA.pdf",
            `secretary_docs/issued/${
              this.StudentDataService.getStudentInfo().studentID
            }`
          );
        }
      }
    );
  }

  generateAction() {
    // const actions = [{
    //   owner: this.StudentDataService.getStudentInfo().studentID,
    //   the_type: 'response',
    //   timestamp: new Date(Date.now()),
    //   description: 'student filled an NDA form',
    //   // data: { msg: this.txtReason }
    // },]
    const ticketDetails = {
      ticket_id: this.ticket_id,
      owner: this.StudentDataService.getStudentInfo().studentID,
      customer: this.StudentDataService.getStudentInfo().studentID,
      sectionOf: "nda",
    };

    this.ServerApiService.newAction({ ticketDetails }).subscribe(
      (data: any) => {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = "reload";
        this.router.navigate(["/administration"]);
      }
    );
  }
}
