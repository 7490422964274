export class CourseStatus {
  private statusArray: string[];
  constructor() {
    this.statusArray = [
      "end",
      "נגמר",
      "stop",
      "stopped",
      "over",
      "complete",
      "completed",
    ];
  }
  // Function that return the status array.
  getCourseStatusArray(): string[] {
    return this.statusArray;
  }
}
