import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComponentsModule } from "./components/components.module";
import { CustomDirectiveModule } from "./directives/custom-directive.module";
import { CustomPipeModule } from "./pipes/custom-pipe.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    CustomDirectiveModule,
    CustomPipeModule,
  ],
  exports: [
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    CustomDirectiveModule,
    CustomPipeModule,
  ],
})
export class SharedModule {}
