import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { SignaturePad } from "ngx-signaturepad/signature-pad";
import { ServerApiService } from "app/services/server-api.service";
import { StudentDataService } from "app/services/students/student-data.service";
import { Course } from "app/models/interfaces/course/course";

@Component({
  selector: "app-freeze-course",
  templateUrl: "./freeze-course.component.html",
  styleUrls: ["./freeze-course.component.css"],
})
export class FreezeCourseComponent implements OnInit {
  @ViewChild("signatureRef") signaturePad: SignaturePad;
  @ViewChild("signatureContainer") signatureContainer: ElementRef;
  signaturePadOptions: Object = {
    // passed through to szimek/signature_pad constructor
    minWidth: 1,
    canvasWidth: 420,
    canvasHeight: 150,
    backgroundColor: "rgb(255,255,255)",
  };
  signature: any = null;

  selectedCourse;
  courses: Course[];
  txtReason: string;
  fullPath: boolean;
  freezeStatus$: Observable<any>;
  state$: any;
  ticket_id: string;

  constructor(
    private ServerApiService: ServerApiService,
    private StudentDataService: StudentDataService,
    private router: Router,
    public activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.courses = this.StudentDataService.getCourses();
    this.freezeStatus$ = this.ServerApiService.getDocument(
      "freeze_status.pdf",
      "secretary_docs/tamplate"
    );
    this.ticket_id = window.history.state.ticket_id;
  }
  freezeWholePathToggle() {
    this.fullPath = !this.fullPath;
  }
  setSelectedCourse(course) {
    this.selectedCourse = course;
  }

  drawComplete(): void {
    this.signature = this.signaturePad.toDataURL();
    console.log(this.signature);
  }
  clearCanvas() {
    this.signaturePad.clear();
  }
  size() {
    this.signaturePad.set(
      "canvasWidth",
      this.signatureContainer.nativeElement.clientWidth
    );
    this.signaturePad.set(
      "canvasHeight",
      this.signatureContainer.nativeElement.clientHeight
    );
  }
  buildFreezeStatusForm() {
    const data = {
      course_name: this.fullPath ? "pathname" : this.selectedCourse.courseName,
      id: this.StudentDataService.getStudentInfo().studentID,
      name: this.StudentDataService.getStudentInfo().firstName,
      lname: this.StudentDataService.getStudentInfo().familyName,
      reason: this.txtReason,
      signature: this.signature,
    };
    this.ServerApiService.buildFreezeForm(data, "freeze_status").subscribe(
      (data: any) => {
        if (data.status) {
          this.freezeStatus$ = this.ServerApiService.getDocument(
            "freeze_status.pdf",
            `secretary_docs/issued/${
              this.StudentDataService.getStudentInfo().studentID
            }`
          );
        }
      }
    );
  }

  generateAction() {
    // const actions = [{
    //   owner: this.StudentDataService.getStudentInfo().studentID,
    //   the_type: 'response',
    //   timestamp: new Date(Date.now()),
    //   description: 'student filled freeze form',
    //   data: { msg: this.txtReason }
    // },]
    const ticketDetails = {
      ticket_id: this.ticket_id,
      owner: this.StudentDataService.getStudentInfo().studentID,
      customer: this.StudentDataService.getStudentInfo().studentID,
      sectionOf: "freeze_course",
    };

    this.ServerApiService.newAction({ ticketDetails }).subscribe(
      (data: any) => {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = "reload";
        this.router.navigate(["/administration"]);
      }
    );
  }
}
