import { Component, OnInit } from "@angular/core";
import { Course } from "app/models/interfaces/course/course";
import { ServerApiService } from "../../../../services/server-api.service";
import { ModalService } from "app/modules/shared/components/modal/modal.service";

@Component({
  selector: "app-students-data-update",
  templateUrl: "./students-data-update.component.html",
  styleUrls: ["./students-data-update.component.css"],
})
export class StudentsDataUpdateComponent implements OnInit {
  studentID: string;
  courses: Course[] = [];
  course: Course;
  showTables: boolean;
  showUpdate: boolean;
  examMark: string;
  projectMark: string;
  constructor(
    private ServerApiService: ServerApiService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {}
  updateMarks() {
    console.log(this.examMark, this.projectMark);
    const updateObj = {
      courseperstudent: [
        {
          student: this.studentID,
          course: this.course.course,
          projectMark: this.projectMark,
          examMark: this.examMark,
        },
      ],
    };
    this.ServerApiService.postCoursesPerStudent(
      JSON.stringify(updateObj)
    ).subscribe((res) => {
      console.log("updated");
      this.getStudent();
    });
    this.closeModal("update-marks");
  }
  // modal window
  openModal(id: string, x: number, y: number, l: number) {
    this.modalService.open(id, x, y, l);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

  loadCourse(course) {
    this.course = course;
    const modalWidth = Math.floor(document.body.offsetWidth * 0.4);
    const x = Math.floor(document.body.offsetWidth * 0.35);
    const y = Math.floor(document.body.offsetHeight * 0.35);
    this.openModal("update-marks", x, y, modalWidth);
  }
  logMe(data) {
    console.log(data);
  }
  getStudent() {
    this.ServerApiService.fetchCoursesSessions(this.studentID).subscribe(
      (data) => {
        this.courses = data;
        this.showTables = true;
        this.showUpdate = false;
      }
    );
  }
  showTmpl(course) {
    this.course = course;
    this.showTables = false;
    this.showUpdate = true;
  }

  openUpdateScoreView(cycle) {
    console.log(cycle);
  }
}
