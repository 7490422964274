import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ServerApiService } from "app/services/server-api.service";
import { saveAs } from "file-saver";
import { IsLoggedService } from "app/services/data-share/is-logged.service";

@Component({
  selector: "app-file",
  templateUrl: "./file.component.html",
  styleUrls: ["./file.component.css"],
  providers: [ServerApiService],
})
export class FileComponent implements OnInit {
  @Input() file;
  @Input() mode;
  @Input() courseName: string;
  askDelete : boolean = false;
  @Output() fileDeleted: EventEmitter<any>;
  @Output() fileNotExist: EventEmitter<any>;
  userData: any
  constructor(private serverApiService: ServerApiService, private isLoggedService: IsLoggedService) {
    this.fileDeleted = new EventEmitter();
    this.fileNotExist = new EventEmitter();
  }

  ngOnInit(): void {
        this.isLoggedService.getData().subscribe(user=>{
        this.userData = user.user
      })
     // console.log('FileComponent - this.userDate:', this.userData);
      console.log('FileComponent - courseName:', this.courseName);
      
    }
    downloadFile(file) {
      this.serverApiService.downloadFile(file._id).subscribe(
        (res) => {
          // console.log('respond from fileDownloads : ',res)
          // console.log('file.fileName on fileDownloads : ',file.fileName)
          const studentData = { studentId : this.userData.studentID, fileName: file.fileName,courseName: this.courseName}
      //    console.log('studentData:',studentData);
   //       console.log('FileComponent - downloadFile:', this.userData);

          this.serverApiService.postFileDownloadLog(studentData).subscribe(ans=>{
              console.log('postFileDownloadLog:',ans);
              
          })
        saveAs(res, file.fileName);
      },
      (err) => {
        console.log('File not Exist :  ',err)
        this.fileNotExist.emit() 
    }
    );
  }

  deleteFile(file) {
    this.serverApiService.deleteFile(file._id).subscribe(
      (ans) => this.fileDeleted.emit(),
      (err) => console.log(err)
    );
  }
}
