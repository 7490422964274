import { Component, Input, OnInit } from '@angular/core';
import { ServerApiService } from "app/services/server-api.service";
import { StudentDataService } from "app/services/students/student-data.service";
import { Subscription } from "rxjs";
import { finalize }  from 'rxjs/operators';
import  {HttpEventType,HttpClient} from '@angular/common/http'

type ServerApiMethods = 'postStudentCV' | 'anotherMethod'; 

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  @Input() functionName: string;
  uploadPointer: any;
  path:any;
  studentId:string |null = null;
  requiredFileType:string;
  fileName:string = ''
  uploadProgress:number;
  uploadSub: Subscription;
  isFinish: boolean = false
  uploadFileName:string|null = null

  constructor(private serverApiService: ServerApiService, private studentDataService: StudentDataService,private http: HttpClient) { }

  ngOnInit(): void {
    this.studentId = this.studentDataService.getStudentInfo().studentID; 
  }
  ngOnDestroy() {
    if (this.uploadSub) {
      this.uploadSub.unsubscribe();
    }
  }
   

  onFileSelected(event: InputEvent): void {
    const input = event.target as HTMLInputElement;
    const file: File | null = input.files ? input.files[0] : null;
    if (file&&this.studentId) {
      console.log('file uploaded ok ');
        this.fileName = file.name;
        const formData = new FormData();
        formData.append("studentId", this.studentId);
        // Extracting the file extension and appending it to formData
        const fileExtension = file.name.split('.').pop();
        if (fileExtension) {
          formData.append("fileExtension", fileExtension);   
        }
        formData.append("file", file,`student_CV_${this.studentId}`);  
        const upload$ = this.serverApiService[this.functionName](formData).pipe(
          finalize(() =>{    
            this.reset();
            input.value = '';})
          
      );
      this.uploadSub = upload$.subscribe(
      event => {
        if (event.type == HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round(100 * (event.loaded / event.total));
          if(this.uploadProgress === 100  ) {
            console.log('File has been Uploaded!');
            this.uploadFileName  =  this.fileName 
            this.fileName = `the File "${this.uploadFileName }" has been Uploaded!`
            this.uploadFileName = null
          }
        }
      },
      error => {
        console.error('File upload failed', error);
        this.fileName = 'Upload failed. Please try again.';
      }
    )
    }
  }
  cancelUpload() {
    this.uploadSub?.unsubscribe();
    this.fileName = null
    this.uploadFileName = null
    this.reset();
  }
  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }



}
