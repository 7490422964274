import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerApiService } from "app/services/server-api.service";

interface myTickets {
  status: string,
  data: []
}

interface myStatus {
  status: string,
  value: Number
}
interface myTicket {
  actions: [],
  customer: string,
  owner: string,
  process: { status: string, open_date: Date, close_date: Date }
  the_type: string,
  user: Object,
  __v: Number,
  _id: string
}

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css']
})
export class AdminPageComponent implements OnInit {
  myStatus: myStatus[] = [{ status: 'open', value: 1 }, { status: 'In-Progress', value: 2 }, { status: 'Closed', value: 3 }]
  isStatusModalOpen: boolean = false
  colorStatus = "red"
  tickets: myTicket[] = []
  endDate = new Date()
  startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  mystartDate = this.formatDate(this.startDate);
  myendDate = this.formatDate(this.endDate);
  filterStatus: '' | 'open' | 'Closed' | 'In-Progress' = '';
  test: any = null
  choosedStatus = null
  
  constructor(
    private ServerApiService: ServerApiService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() { }

  filterTicket() {
    console.log('mystartDate', this.mystartDate)
    console.log('myendDate', this.myendDate)
    console.log('filterStatus', this.filterStatus);
    this.tickets = null
    this.ServerApiService.getTicketsByDate(this.mystartDate, this.myendDate, this.filterStatus).subscribe((tickets: myTickets) => {
      this.tickets = tickets.data
      console.log('tickets', this.tickets)
    })
  }

  toggleModal() {
    this.isStatusModalOpen = !this.isStatusModalOpen;
  }

  chooseStatus() {
    console.log('status changed:', this.choosedStatus);
    const ticketIdx = this.tickets.findIndex(ticket => ticket._id == this.choosedStatus.index);
    console.log('On ticket at index:', ticketIdx);
    let statusStr;
    switch (this.choosedStatus.value) {
      case 1:
        this.colorStatus = 'red';
        statusStr = 'open';
        break;
      case 2:
        this.colorStatus = 'rgb(205 208 83)';
        statusStr = 'In-Progress';
        break
      case 3:
        this.colorStatus = 'green';
        statusStr = 'Closed';
        break;
      default:
        this.colorStatus = 'red';
        statusStr = 'open';
    }
    this.tickets[ticketIdx].process.status = statusStr;
    this.isStatusModalOpen = false;
    this.ServerApiService.updateTicketStatus(this.choosedStatus.index, statusStr)
      .subscribe((_data) => {
        this.snackBar.open('Updated status.', '', { duration: 2000, panelClass: ['snackbar-green'] })
      }, ({ error }) => {
        this.snackBar.open(JSON.stringify(error), '', { duration: 5000, panelClass: ['snackbar-red'] })
      })
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }
  formatDate(date) {
    return [
      date.getFullYear(),
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
    ].join("-");
  }
  toDay() {
    //  const  end =  new Date().setDate(new Date().getDate() + 1)
    const start = new Date()
    const end = new Date();
    const days = 1;
    end.setDate(end.getDate() + days)
    this.mystartDate = this.formatDate(start);
    this.myendDate = this.formatDate(end);
  }
  standart() {
    this.mystartDate = this.formatDate(this.startDate);
    this.myendDate = this.formatDate(this.endDate);
  }
}
