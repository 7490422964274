import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MyTicket } from "app/models/interfaces/message/tickets/myTicket";

@Component({
  selector: "app-action-select",
  templateUrl: "./action-select.component.html",
  styleUrls: ["./action-select.component.css"],
})
export class ActionSelectComponent implements OnInit {
  @Input() ticket: MyTicket;
  @Input() type: string;
  allowedActions: [];
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.allowedActions = this.ticket.allowed_actions;
  }

  showActionStatus(myAction) {
    for (let index = 0; index < this.allowedActions.length; index++) {
      if (myAction === this.allowedActions[index]) {
        return true;
      } else {
        return false;
      }
    }
  }

  navigate(theurl) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    // this.router.navigate([`/administration/${theurl}`])
    console.log(this.ticket._id);
    this.router.navigateByUrl(`/administration/${theurl}`, {
      state: { ticket_id: this.ticket._id },
    });
  }
}
