import { Component, ViewChild } from "@angular/core";
import { DatePipe } from "@angular/common";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { EventInput } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGrigPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { ModalService } from "app/modules/shared/components/modal/modal.service";
import { StudentCalendar } from "app/models/interfaces/student/studentCalendar";
import { StudentDataService } from "app/services/students/student-data.service";

@Component({
  selector: "fullcal-cmp",
  moduleId: module.id,
  templateUrl: "fullcall.component.html",
  styleUrls: ["fullcal.component.css"],
})
export class FullCalComponent {
  calendar: StudentCalendar[] = [];
  calEvent: StudentCalendar;
  /* = {
    code:0,
    course_name:'',
    sessionNum:0,
    location:'',
    type:'',
    sessionDate:null, 
    sessionEndDate:null, 
    teacher:'',
   }; */
  eventColor: any;
  @ViewChild("calendar") calendarComponent: FullCalendarComponent; // the #calendar in the template
  calendarVisible = true;
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  calendarWeekends = true;
  calendarEvents: EventInput[] = []; //{ title: 'Event Now', start: new Date() }

  sessionDate;
  sessionEndDate;
  constructor(
    private modalService: ModalService,
    private datePipe: DatePipe,
    private StudentDataService: StudentDataService
  ) {
    let color: string = "";
    /*
    this.IsLoggedService.studentCalendar.subscribe((calendar:StudentCalendar[] ) => {
      console.log('fullcal',calendar);
      this.calendar = calendar;
    }); */
    this.calendar = this.StudentDataService.getCalendar();
    this.calEvent = this.calendar[0];
    for (let i = 0; i < this.calendar.length; i++) {
      if (this.calendar[i].sessionDate !== null) {
        if (this.calendar[i].type === "Lesson") color = "#2196f3";
        else if (this.calendar[i].type === "Training") color = "#009688";
        else if (this.calendar[i].type === "Test") color = "#673ab7";
        else if (this.calendar[i].type === "Cancel Lesson") color = "#ff5722";
        try{
          this.sessionDate = this.datePipe.transform(
            new Date(this.calendar[i].sessionDate),
            "HH:mm"
          );
          this.sessionEndDate = this.datePipe.transform(
            new Date(this.calendar[i].sessionEndDate),
            "HH:mm"
          );
        }catch (e){
          console.log(`sessionDate: ${e}`)
        }
        this.calendarEvents = this.calendarEvents.concat({
          title: this.calendar[i].courseName,
          start: this.calendar[i].sessionDate,
          textColor: "#FFFFFF",
          borderColor: color,
          backgroundColor: color,
          course_name: this.calendar[i].courseName,
          sessionNum: this.calendar[i].sessionNum,
          location: this.calendar[i].location,
          sessionDate: this.sessionDate,
          sessionEndDate: this.sessionEndDate,
          teacher: this.calendar[i].teacher,
          type: this.calendar[i].type,
        });
      }
    }
  }
  toggleVisible() {
    this.calendarVisible = !this.calendarVisible;
  }
  toggleWeekends() {
    this.calendarWeekends = !this.calendarWeekends;
  }
  gotoPast() {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.gotoDate("2000-01-01"); // call a method on the Calendar object
  }
  // modal window
  openModal(id: string, x: number, y: number, l: number) {
    this.modalService.open(id, x, y, l);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  eventClick(info) {
    // prepare data
    // console.log("info", info);
    try{
      this.calEvent.courseName = info.event.extendedProps.course_name;
      this.calEvent.sessionNum = info.event.extendedProps.sessionNum;
      this.calEvent.location = info.event.extendedProps.location;
      this.calEvent.sessionDate = info.event.extendedProps.sessionDate;
      this.calEvent.sessionEndDate = info.event.extendedProps.sessionEndDate;
      this.calEvent.teacher = info.event.extendedProps.teacher;
      this.calEvent.type = info.event.extendedProps.type;
      this.eventColor = info.event.backgroundColor;
      let modalWidth = 150;
      let modalHeight = 200;
      let x: number =
        info.jsEvent.clientX;
      let y: number =
        info.jsEvent.clientY;
      if (y + modalHeight > document.body.offsetHeight)
        y = document.body.offsetHeight - modalHeight; //y - modalHeight - 10;
      if (y <= 0) y = 10;
      //console.log('x' ,x+modalWidth ,'y' , y , 'with',document.body.offsetWidth , 'high' , document.body.offsetHeight)
      this.openModal("custom-modal-1", x, y, modalWidth);
    } catch (e) {
      console.log(`ERROR: ${e}`)
    }
  }
  mouseEnter(info) {
    info.jsEvent.toElement.style.cursor = "pointer";
  }
  mouseLeave(info) {
    info.jsEvent.toElement.style.cursor = "";
  }
  handleDateClick(arg) {
    //this.closeModal('custom-modal-1');
  }
}
