/* Angular imports. */
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';

// import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
// import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
// import { MatMomentDateModule } from '@angular/material-moment-adapter';



/* Import the widget module that holds the angular material module libraries. */
import { WidgetModule } from "app/modules/widget/widget.module";
/* Import the shared module that holds the shared components, directive and pipes. */
import { SharedModule } from "app/modules/shared/shared.module";

/* Auth guard. */
import { AuthGuard } from "app/auth.guard";

/* Student component pages. */
import { TableListComponent } from "./components/table-list/table-list.component";
import { FullCalComponent } from "./components/fullcal/fullcal.component";
import { LessonsTabComponent } from "../misc/components/lessons-tab/lessons-tab.component";
import { StudentsDataUpdateComponent } from "./components/students-data-update/students-data-update.component";
import { QuillModule } from "ngx-quill";
import { FullCalendarModule } from "@fullcalendar/angular";
import { MiscModule } from "../misc/misc.module";
import { VideoPageComponent } from './pages/video-page/video-page.component';
import { VideoListComponent } from '../students/components/video-list/video-list.component';
import { RecruitmentComponent } from './pages/recruitment/recruitment.component';
import { InternshipComponent } from './pages/internship/internship.component';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { PlacementComponent } from './pages/placement/placement.component';
import {MatIconModule} from '@angular/material/icon';
import { GraduationCertificateComponent } from './pages/graduation-certificate/graduation-certificate.component';
import { StudentGraduationCertificateComponent } from "../administration/components/newTickets/student-graduation-certificate/student-graduation-certificate.component";
// import {FileUploadComponent} from "app/modules/shared/components/file-upload/file-upload.component"

const routes: Routes = [
  {
    path: "",
    component: TableListComponent,
    pathMatch: "full",
  },
  {
    path: "table-list/:course/:cycle/:lesson",
    component: LessonsTabComponent,
    canActivate: [AuthGuard],
  },
  { path: "fullcal", component: FullCalComponent, canActivate: [AuthGuard] },
  {
    path: "students-data",
    component: StudentsDataUpdateComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [
    FullCalComponent,
    TableListComponent,
    StudentsDataUpdateComponent,
    VideoPageComponent,
    VideoListComponent,
    RecruitmentComponent,
    InternshipComponent,
    PlacementComponent,
    GraduationCertificateComponent,
    StudentGraduationCertificateComponent

  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FullCalendarModule,
    SharedModule,
    WidgetModule,
    MiscModule,
    QuillModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,

    // MatDatetimepickerModule,
    // MatMomentDatetimeModule ,
    // MatMomentDateModule
    // FileUploadComponent

  ],
  exports: [
    RouterModule,
    FullCalComponent,
    TableListComponent,
    StudentsDataUpdateComponent,
  ],
})
export class StudentsModule {}
