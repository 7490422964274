import { Component, OnInit,ViewChild,ElementRef,ViewContainerRef,ComponentFactoryResolver      } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import {MatRadioModule} from '@angular/material/radio';

import { IsLoggedService } from "app/services/data-share/is-logged.service";
import { TeacherDataService } from "app/services/teachers/teacher-data.service";
import { StudentDataService } from "app/services/students/student-data.service";
import { ServerApiService } from "app/services/server-api.service";

import { TeacherCalendar } from "app/models/interfaces/teacher/teacherCalendar";
import { StudentCalendar } from "app/models/interfaces/student/studentCalendar";
import { User } from "app/models/interfaces/user/user";
import { LessonDate } from "app/models/interfaces/date";
import { Course } from "app/models/interfaces/course/course";

import {MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
  selector: "app-lessons-tab",
  templateUrl: "./lessons-tab.component.html",
  styleUrls: ["./lessons-tab.component.css"],
  // standalone: true,
  // imports: [MatFormFieldModule, MatInputModule, MatButtonModule, MatSnackBarModule],
})
export class LessonsTabComponent implements OnInit {
  @ViewChild('myDialog', { static: true }) myDialog!: ElementRef;
  @ViewChild('myDialogWorning', { static: true }) myDialogWorning!: ElementRef;
  @ViewChild('myDialogNotFinishYet', { static: true }) myDialogNotFinishYet!: ElementRef;
  // @ViewChild('myDialog', { static: true }) myDialog!: HTMLDialogElement;
  // @ViewChild('dynamicComponentContainer', { read: ViewContainerRef, static: true }) dynamicComponentContainer: ViewContainerRef;

  selectedOption = 0
  displayStyle = 'none';
  displayStyle2 = 'none'
  questions = []
  freeText = []
  ans1
  ans0
  answers = []
  userId = null
  snooze:number = 3
  course;
  private mode: number;
  private lessons;
  isComplete: boolean;

  lessonTypeConverter: Object = {
    1: "Lesson",
    2: "Traning",
    3: "Project",
    4: "Cancled",
  };
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private IsLoggedService: IsLoggedService,
    private TeacherDataService: TeacherDataService,
    private StudentDataService: StudentDataService,
    private serverApiService: ServerApiService ,
    private _snackBar: MatSnackBar,
    private componentFactoryResolver: ComponentFactoryResolver
    // private MatFormFieldModule: MatFormFieldModule,
    // private MatInputModule: MatInputModule,
    // private MatSnackBarModule: MatSnackBarModule,
    
  ) {}
  public isActive = 2;
  ngOnInit(): void {
    this.IsLoggedService.getData().subscribe((data: User) => {
      this.userId = data.user.studentID
      this.mode = data.user.role;
      if (data.user.role === 2) {
        this.loadTeacher();
      }
      if (data.user.role === 1) {
        this.loadStudent();
      }
      //this.isActive = this.getActiveLesson();
      console.log(this.isActive);
    }); 
  }


//***************for dialog******************//
  openDialog() {
    console.log('VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV')
   // this.myDialog.showModal();
  }

  closeDialog() {
 //   this.myDialog.close();
  }

//*********************************//
  customStylesPills(lessonNum) {
    if (lessonNum === this.isActive) {
      return "nav-link active";
    }
    if (lessonNum < this.isActive) {
      return "nav-link passed";
    } else return "nav-link";
  }
  updateUrl(lesson) {
    const curLoc = this.location.path();
    this.location.replaceState(
      curLoc.substring(0, curLoc.length - 1) + `${lesson}`
    );
  }
  private getActiveLesson() {
    let activeLesson = this.lessons[this.lessons.length - 1].session_num;
    for (let index = 0; index < this.lessons.length; index++) {
      if (
        new Date(this.lessons[index].sessionDate).getTime() >
        new Date(Date.now()).getTime()
      ) {
        activeLesson = this.lessons[index].session_num;
        break;
      }
    }
    return activeLesson;
  }
  private loadStudent(): void {
    this.getCourseS();
    this.getCalendarS();
	  console.log('loadStudent:119 - all lessons:==========>',this.lessons)  
    if(this.lessons.length>5){
        const today = Date.now()
        // const wanabeDate =  new Date(this.lessons[0].sessionDate)
        // console.log('wanabeDate',wanabeDate)
        console.log('mydate',today)
        this.serverApiService.getQuestionnaireCompletion(this.userId,this.lessons[0].cyclecode).subscribe((ans)=>{
          this.isComplete = ans.isQuestionnaire_completion
          console.log('this.isComplete fron DB:',this.isComplete)
        
       // for(let i = 5; i<this.lessons.length; i++){
          let t =  new Date(this.lessons[5].sessionDate).getTime()
          if((t<today)){
            console.log('t<today','lessonDate- t:',t, 'today:',today)
            if(!this.isComplete){
                this.serverApiService.getQuestionnaire(this.lessons[0].cyclecode).subscribe((allQuestionsFomDB)=>{
                    console.log('allQuestionsFomDB===>:',allQuestionsFomDB)
                        for(let i = 0; i<allQuestionsFomDB.length; i++){
                              allQuestionsFomDB[i].ans = null;
                              (allQuestionsFomDB[i].txt_or_number == 'number')?  this.questions.push(allQuestionsFomDB[i]) : this.freeText.push(allQuestionsFomDB[i])
                    }
                    console.log(' this.questions===>:', this.questions)
                    console.log(' this.freeText===>:', this.freeText)
                    this.displayStyle = "block";
                    this.serverApiService.getSnooze(this.userId,this.lessons[0].cyclecode).subscribe((ans)=>{
                      this.snooze = ans.snooze
                      console.log('this.snooz fron DB:',this.snooze)
                    })
                })  
          //    break
            }
          }
          else{
            console.log('no')
          }
        //   if(i<this.lessons.length-1){
        //     let y =  new Date(this.lessons[i+1].sessionDate)
        //     if((t< mydate)&&(mydate<y)){
        //       console.log('yes')
        //     }
        //     else{
        //       console.log('no')
        //     }
        //   //}
        // }
        
   // }
  })
  }
}
  private loadTeacher(): void {
    this.getCourseT();
    this.getCalendarT();
    console.log('loadTeacher ; this.lessons=============>',this.lessons)
  }

  private getCourseS(): void {
    console.log(this.StudentDataService.getCourses());
    const filtered = this.StudentDataService.getCourses().filter(
      (course: Course) => {
       // console.log(   course.cycle === Number(this.route.snapshot.paramMap.get("cycle")),course.cycle, course );
        if (course.cycle === Number(this.route.snapshot.paramMap.get("cycle")))
          return true;
      }
    );
    this.course = filtered[0];
    console.log(filtered);
  }
  private getCalendarS(): void {
    console.log(this.StudentDataService.getCalendar())
    this.lessons = this.StudentDataService.getCalendar().filter(
      (lesson: StudentCalendar) => {
        if (
          lesson.courseName === this.course.courseName &&
          lesson.cyclecode === this.course.cycle
        ) {
          return true;
        }
      }
    );
  }
  private getCourseT(): void {
    console.log(this.TeacherDataService.getTeacherCourse());
    const filtered = this.TeacherDataService.getTeacherCourse().filter(
      (course) => {
        return (
          course.courseName === this.route.snapshot.paramMap.get("course") &&
          course.code.toString() === this.route.snapshot.paramMap.get("cycle")
        );
      }
    );
    // const filtered2 = filtered.filter(course=>{return this.course.code === course.code})
    console.log(filtered);
    this.course = filtered[0];
  }
  private getCalendarT(): void {
    console.log(this.TeacherDataService.getTeacherCalendar())
    this.lessons = this.TeacherDataService.getTeacherLessons().filter(
      (lesson: TeacherCalendar) => {
        if (
          lesson.courseName === this.course.courseName &&
          lesson.cyclecode === this.course.code
        ) {
          return true;
        }
      }
    );
	console.log('lessons',this.lessons)
  }

  getRreformatedDates(lesson): LessonDate {
    const start = new Date(lesson.sessionDate);
    const end = new Date(lesson.sessionEndDate);
    const decorate = (num) => {
      return num < 10 ? "0" + num : num;
    };
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return {
      date: {
        year: start.getUTCFullYear().toString(),
        month: decorate(start.getUTCMonth().toString()),
        day: decorate(start.getDate().toString()),
      },
      time: {
        dayOfWeek: days[start.getUTCDay()],
        start: {
          hours: decorate((start.getHours()).toString()),
          minutes: decorate(start.getMinutes().toString()),
        },
        end: {
          hours: decorate((end.getHours()).toString()),
          minutes: decorate(end.getMinutes().toString()),
        },
      },
    };
  }
  goBack() {
    this.location.back();
  }
  onSkipForNow(){
    console.log('on closePopup, this.snooze==>',this.snooze);
   const message = 'You have reached the maximun number times for closing this window!';
   const action = 'Dismiss';
   this.snooze++
   console.log('this.snooze===================>',this.snooze)
    if(this.snooze<4){
      this.serverApiService.postSnooze(this.userId,this.lessons[0].cyclecode).subscribe(
        (data: any) => {
          console.log('lesson-tab.compenent 301')
        });
    }
    if(this.snooze>3){
      this.myDialogWorning.nativeElement.showModal();
    }else{
      this.displayStyle = "none";
    }

  }

  onFinish(){
    // if the user didnt fill all the questions!
    for (let i = 0; i< this.questions.length; i++) {
      if(!this.questions[i].ans){
        this.myDialogNotFinishYet.nativeElement.showModal();
        return 
      }
    }
    for (let i = 0; i< this.freeText.length; i++) {
      if(!this.freeText[i].ans){
        this.myDialogNotFinishYet.nativeElement.showModal();
        return 
      }
    }
    this.answers = this.questions.concat(this.freeText)
    console.log('qnswers:=>', this.answers)
    this.answers.push({cyclecode: this.lessons[0].cyclecode})
    this.answers.push({userId:  this.userId})
    this.displayStyle = "none";
    this.serverApiService.postQuestionnaireAnswers(this.answers).subscribe(
      (data: any) => {
        console.log('lesson-tab.component 301')
      }
    );
    // this.serverApiService.postQuestionnaireAnswers(this.answers)
  }
  onEerythingIsGreat(){
    for(let i = 0; i<this.questions.length; i++){
      this.questions[i].ans = 5
    }
    this.freeText[0].ans = 'Everything Is Great'
    this.freeText[1].ans = 'Everything Is Great'
    this.answers = this.questions.concat(this.freeText)
    this.answers.push({cyclecode: this.lessons[0].cyclecode})
    this.answers.push({userId:  this.userId})
    this.serverApiService.postQuestionnaireAnswers(this.answers).subscribe(
      (data: any) => {
        console.log('lesson-tab.component 329')
      }
    );
    console.log('qnswers:=>', this.questions)
    this.displayStyle = "none";

    this.myDialog.nativeElement.showModal();

  }
  closeEverithingIsGreatModal(){
    this.myDialog.nativeElement.close();
  }
  closeCannotSkippModal(){
    this.myDialogWorning.nativeElement.close();
  }
  closeNotFinishYetModal(){
    this.myDialogNotFinishYet.nativeElement.close();
  }
}
