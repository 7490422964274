import { Component, OnInit } from '@angular/core';
import { Course } from "app/models/interfaces/course/course";
import { StudentCalendar } from "app/models/interfaces/student/studentCalendar";
import { ServerApiService } from "app/services/server-api.service";
import { StudentDataService } from "app/services/students/student-data.service";
import { ModalService } from "app/modules/shared/components/modal/modal.service";

@Component({
  selector: 'app-solaredge-course-list',
  templateUrl: './solaredge-course-list.component.html',
  styleUrls: ['./solaredge-course-list.component.css']
})
export class SolaredgeCourseListComponent implements OnInit {
  courses: Course[] = [];
  calendar: StudentCalendar[];

  constructor(    private ServerApiService: ServerApiService,
    private StudentDataService: StudentDataService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
   this.calendar = this.StudentDataService.getCalendar();
   this.ServerApiService.fetchSolaredgeCourses().subscribe((courses) => {
    this.courses = courses
    console.log('fetchSolaredgeCourses',courses)
   })
  }


}
