import { Pipe, PipeTransform } from "@angular/core";
import { ServerApiService } from "app/services/server-api.service";

@Pipe({
  name: "reformatStudentHW",
})
export class ReformatStudentHWPipe implements PipeTransform {
  constructor(private ServerApiService: ServerApiService) {}
  async transform(value: any, cycle: any, ...args: unknown[]): Promise<any> {
    const req: Promise<any> =
      this.ServerApiService.fetchCycleStudents(cycle).toPromise();
    const res: any = await req;
    console.log(value, cycle, res);
    const hwStudentsTemp = [];
    if (!(res.length > 0)) {
      return;
    }
    res.forEach((student: any) => {
      let temp = [];
      temp = value.filter((file) => {
        return file.filePath[0].split(",")[5] === student.studentID;
      });
      if (!(temp.length > 0)) {
        temp = [];
      }
      hwStudentsTemp.push({
        id: student.studentID,
        lname: student.familyName,
        fname: student.firstName,
        files: temp,
      });
    });
    console.log(hwStudentsTemp);
    return hwStudentsTemp;
  }
}
