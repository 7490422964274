import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LogPipe } from "./log.pipe";
import { ReformatStudentHWPipe } from "./reformat-student-hw.pipe";
import { SanitizePipe } from "./sanitize.pipe";

@NgModule({
  declarations: [LogPipe, ReformatStudentHWPipe, SanitizePipe],
  imports: [CommonModule],
  exports: [LogPipe, ReformatStudentHWPipe, SanitizePipe],
})
export class CustomPipeModule {}
