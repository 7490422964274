import { ServerApiService } from "app/services/server-api.service";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class AuthService {
  private isAuth: boolean = false;

  constructor(private ServerApiService: ServerApiService) {}

  login() {
    this.isAuth = true;
  }

  logout() {
    this.ServerApiService.logOut().subscribe((res: any) => {
      if (res.status) {
        sessionStorage.clear();
        this.isAuth = false;
        location.reload();
      }
    });
  }

  isAuthenticated() {
    return this.isAuth;
  }
}
