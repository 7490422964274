import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitize'
})

export class SanitizePipe implements PipeTransform {
  constructor(private DomSanitizer:DomSanitizer){}
  transform(value: Blob, ...args: unknown[]): unknown {
    const url = URL.createObjectURL(new Blob([value], { type: 'application/pdf' }))
    console.log(url)
    return this.DomSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
