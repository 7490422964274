import { Injectable, Output, EventEmitter } from "@angular/core";
import { forkJoin } from "rxjs";

/* Service imports. */
import { ServerApiService } from "app/services/server-api.service";
import { IsLoggedService } from "app/services/data-share/is-logged.service";

/* model imports. */
import { TeacherCalendar } from "app/models/interfaces/teacher/teacherCalendar";
import { TeacherCycles } from "app/models/interfaces/cycle/cycleTeacher";
import { linkStatus } from "app/models/interfaces/linkStatus";
import { User } from "app/models/interfaces/user/user";
import { SocketService } from "../socket.service";

@Injectable({ providedIn: "root" })
export class TeacherDataService {
  private user: User;
  private courses: TeacherCycles[];
  private calendar: TeacherCalendar[];
private lessons: TeacherCalendar[];
 
 @Output() teacherloadend = new EventEmitter();
 path = null
 checkWindowClosed: any;
  constructor(
    private ServerApiService: ServerApiService,
    private IsLoggedService: IsLoggedService,
    private socketService: SocketService
  ) {
    this.IsLoggedService.getData().subscribe((data: User) => {
      let mystartDate = null // <=================== update ====================================	
      let myendDate = null;	
      let myChoosedPath = 'All'	
      let myTimeSelected = 'All'	
      const today = new Date();	
  

      const endDate =  new Date(new Date().setFullYear(new Date().getFullYear() + 1))	
      const startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1))	

      mystartDate = this.formatDate(startDate);	
      myendDate =  this.formatDate(endDate);	
      console.log('this.user.user.role === ')
      this.user = data;
      if (this.user.user.role === 2) {
        forkJoin([
          this.ServerApiService.fetchTeacherCalendarTeacher(this.user.user.studentID),
          this.ServerApiService.fetchTeacherCalendarTrainer(this.user.user.studentID),
          this.ServerApiService.fetchTeacherCalendar(this.user.user.studentID),
          this.ServerApiService.fetchTeacherPath(this.user.user.studentID) ,  // <=================== update ====================================
          this.ServerApiService.fetchTeacherCyclesByFilter(this.user.user.studentID,mystartDate,myendDate,myChoosedPath,myTimeSelected)   // <=================== update ====================================	

        ]).subscribe(
          ([calendar,calendar2,lessons,path,coursesbyFilter]: [TeacherCalendar[],TeacherCalendar[],TeacherCalendar[], TeacherCycles[], TeacherCycles[]]) => {	
            this.verifyLinks(coursesbyFilter);	
            this.courses = coursesbyFilter;	
            this.calendar = [...calendar,...calendar2];	
	          this.lessons = lessons;
            console.log(this.calendar)
            this.teacherloadend.emit();
            this.path = path
          }
        );
      }
    });
  }

  verifyLinks(courses: TeacherCycles[]) {
    console.log('courses,  50',courses)
    courses.map((course) => {
      if (course.url_conference) {
        this.ServerApiService.checkLink(
          course.url_conference.toString()
        ).subscribe((ans: linkStatus) => {
          if (!ans.status) {
            course.url_conference = null;
          }
        });
      }
    });
  }

  // for safari and chrome browsers
  giveMeHostLink(meeting: number, host_acc, cycle) {
    console.log('TeacherDataService - giveMeHostLink:', meeting, host_acc);
  
    // Subscribe to the API call
    this.ServerApiService.giveMeHostLink(meeting, host_acc.key, cycle).subscribe(
      (data: string) => {
        console.log(data);
  
        // Create a reference for the new window
        let newWindow = null;
  
        // Safari workaround for popup blockers - create window immediately in response to user action
        try {
          newWindow = window.open("", "_blank"); // Open a blank window immediately
        } catch (err) {
          console.log('Popup blocked or failed to open:', err);
        }
  
        // Check if the new window was opened successfully
        if (newWindow) {
          // Once the URL is received, change the location of the opened window
          newWindow.location.href = data;
  
          // Monitor the window status to detect when it's closed
          this.checkWindowClosed = setInterval(() => {
            if (newWindow.closed) {
              console.log('newWindow has been closed!');
              // Release the user when the window is closed
              this.socketService.releaseUnickoUser(host_acc.value);
              clearInterval(this.checkWindowClosed);
            }
          }, 500);
        } else {
          console.log('Failed to open new window. It might have been blocked.');
        }
      },
      (error) => {
        console.log("Error occurred while getting host link:", error);
      }
    );
  }
  
  
    // not compatible for safari browser
  // giveMeHostLink(meeting: number, host_acc, cycle) {
  //   console.log('TeacherDataService - giveMeHostLink:',meeting, host_acc);
  //   this.ServerApiService.giveMeHostLink(meeting, host_acc.key, cycle).subscribe(
  //     (data: string) => {
  //       console.log(data);
  //       const newWindow = window.open(data, "_blank");
  //       this.checkWindowClosed = setInterval(() => {
  //         if (newWindow.closed) {
  //             clearInterval(this.checkWindowClosed);
  //             console.log('newWindow has been closed!');
  //             this.socketService.releaseUnickoUser(host_acc.value)
  //         }
  //     }, 500)
  //     },
  //     (error) => {
  //       console.log("err!!", error);
  //     }
  //   );
  // }

  getTeacherCourse() {
    return this.courses;
  }

  getTeacherID() {
    return this.user.user.studentID;
  }

  getTeacherCalendar() {
    return this.calendar;
  }
  getTeacherLessons() {
    return this.lessons;
  }

     /********************************** update **************************** */	
     fetchTeacherCyclesByFilter(startDate: String,endDate : String, choosedPath: string, timeSelected : String){	
      return this.ServerApiService.fetchTeacherCyclesByFilter(this.user.user.studentID,startDate,endDate, choosedPath,timeSelected)	
      // .subscribe((ans)=>{	
      //   console.log(' ans================>:', ans)	
      //   return ans	
      // })	
    }	
     getPath(){	
      // return  new Promise((resolve, reject) => {	
      //   resolve(this.path);	
      // });	
      return this.ServerApiService.fetchTeacherPath(this.user.user.studentID)   // <=================== update ====================================	
    }	
    formatDate(date) {	
      return [	
          date.getFullYear(),	
          this.padTo2Digits(date.getMonth() + 1),	
          this.padTo2Digits(date.getDate()),	
      ].join("-");	
  }	
    padTo2Digits(num) {	
      return num.toString().padStart(2, "0");	
  }	
    /********************************************************* */

}
