import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router, RoutesRecognized } from '@angular/router';
import { ServerApiService } from "app/services/server-api.service";
import { CycleStudents } from "app/models/interfaces/cycle/cycleStudent";

@Component({
  selector: 'app-solaredge-students',
  templateUrl: './solaredge-students.component.html',
  styleUrls: ['./solaredge-students.component.css']
})
export class SolaredgeStudentsComponent implements OnInit {
  sub :any
  id :number
  cycleStudents : Array<CycleStudents>

  constructor(private route:ActivatedRoute, private router:Router, private ServerApiService: ServerApiService) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
        this.id = params['id'];
    });
    console.log('this id : ',this.id);
    this.getStudentsByCourse(this.id)
}

getStudentsByCourse(courseSessionCode){
  console.log('courseSessionCode  === >',courseSessionCode)
  this.ServerApiService.fetchCycleStudents(courseSessionCode).subscribe(
    (data: CycleStudents[]) => {
      console.log("students data=========>", data);
      this.removeGuest(data)
      this.cycleStudents = data;
    })
}
removeGuest(data){
  const index = data.findIndex((user)=>{
   return user.studentID == '123456789'
  })
  console.log("index=========>", index);
  data.splice(index,1) 
}


}
