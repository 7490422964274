import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppStyleDirective } from "./app-style.directive";
import { CalStyleDirective } from "./cal-style.directive";
import { ThisDayDirective } from "./thisday.directive";

@NgModule({
  declarations: [AppStyleDirective, CalStyleDirective, ThisDayDirective],
  imports: [CommonModule],
  exports: [AppStyleDirective, CalStyleDirective, ThisDayDirective],
})
export class CustomDirectiveModule {}
