/* Angular imports. */
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http"; // Important
import { RouterModule } from "@angular/router";
import { DatePipe } from "@angular/common";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";

/* App imports. */
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";

/* Ngx modules. */
import { ToastrModule } from "ngx-toastr";
import { QuillModule } from "ngx-quill";

/* Full calendar module. */
import { FullCalendarModule } from "@fullcalendar/angular";

/* Services. */
import { ServerApiService } from "./services/server-api.service";
import { StudentDataService } from "./services/students/student-data.service";
import { FileServiceService } from "./services/files/file-service.service";
import { ChatService } from "./services/contact/chat/chat.service";

/*socket*/
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
// import { SocketService } from './services/socket.service';
// import { environment } from "../environments/environment";
// let baseUrl = `${environment.HOST}`;
// const config: SocketIoConfig = { url: `${baseUrl}`, options: {} };
// console.log('environment on app.module =======>:',environment)

/* Custom modules. */
import { WidgetModule } from "./modules/widget/widget.module";
import { SharedModule } from "./modules/shared/shared.module";

/* Application elements */
import { AdminstrationModule } from "./pages/administration/adminstration.module";
import { AdministrationComponent } from "./pages/administration/administration.component";
import { StudentsModule } from "./pages/students/students.module";
import { TeachersModule } from "./pages/teachers/teachers.module";
import { ContactModule } from "./pages/contact/contact.module";
import { MiscModule } from "./pages/misc/misc.module";
import { LoginComponent } from "./pages/login/login.component";
import { UserProfileComponent } from "./pages/user-profile/user-profile.component";
import { APPBASEHREFService } from "./services/app-base-href.service";
import { OutsourceCalendarComponent } from './pages/outsource-calendar/outsource-calendar.component';
import { OutsourceCoursesListComponent } from './pages/outsource-courses-list/outsource-courses-list.component';
import { OutsourceStudentsDataComponent } from './pages/outsource-students-data/outsource-students-data.component';
import { SolaredgeCourseListComponent } from './pages/solaredge-course-list/solaredge-course-list.component';
import { SolaredgeStudentsComponent } from './pages/solaredge-students/solaredge-students.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
// components/newTickets/student-graduation-certificate/student-graduation-certificate.component

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {  MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    WidgetModule,
    RouterModule,
    HttpClientModule,
    FullCalendarModule,
    ToastrModule.forRoot(),
    QuillModule.forRoot(),
    AdminstrationModule,
    ContactModule,
    MiscModule,
    StudentsModule,
    TeachersModule,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatCheckboxModule,
    BrowserModule,

  ],
  declarations: [
    AppComponent,
    AdministrationComponent,
    LoginComponent,
    UserProfileComponent,
    OutsourceCalendarComponent,
    OutsourceCoursesListComponent,
    OutsourceStudentsDataComponent,
    SolaredgeCourseListComponent,
    SolaredgeStudentsComponent,
    AdminPageComponent,
    
    
    
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
    ServerApiService,
    APPBASEHREFService,
    ChatService,
    DatePipe,
    StudentDataService,
    FileServiceService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
