import { Component, OnInit } from '@angular/core';
import { IsLoggedService } from 'app/services/data-share/is-logged.service';

@Component({
  selector: 'app-graduation-certificate',
  templateUrl: './graduation-certificate.component.html',
  styleUrls: ['./graduation-certificate.component.css']
})
export class GraduationCertificateComponent implements OnInit {
  isGraduationCertificate: boolean = false
  constructor( private IsLoggedService: IsLoggedService,) { }

  ngOnInit(): void {
    this.IsLoggedService.isGraduationCertificate.subscribe(ans => {
      this.isGraduationCertificate = ans ? true:false;
    })
  }

}
