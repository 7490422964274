import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SignaturePadModule } from "ngx-signaturepad";

/* Import the widget module that holds the angular material module libraries. */
import { WidgetModule } from "app/modules/widget/widget.module";
/* Import the shared module that holds the shared components, directive and pipes. */
import { SharedModule } from "app/modules/shared/shared.module";

import { TicketListComponent } from "app/pages/administration/components/ticket-list/ticket-list.component";

import { StudentCertComponent } from "app/pages/administration/components/newTickets/student-cert/student-cert.component";
import { ReportCardComponent } from "app/pages/administration/components/newTickets/report-card/report-card.component";
import { TechnicalComponent } from "app/pages/administration/components/newTickets/technical/technical.component";
import { TeacherComponent } from "app/pages/administration/components/newTickets/teacher/teacher.component";

import { ChooseTicketComponent } from "./components/newTickets/choose-ticket/choose-ticket.component";
import { ChooseSectionComponent } from "./components/newTickets/choose-section/choose-section.component";
import { ChooseTechIsuesComponent } from "./components/newTickets/choose-tech-isues/choose-tech-isues.component";
import { ChooseAdminIsuesComponent } from "./components/newTickets/choose-admin-isues/choose-admin-isues.component";
import { ChooseEducationIsuesComponent } from "./components/newTickets/choose-education-isues/choose-education-isues.component";
import { ChangeExamDateComponent } from "./components/newTickets/change-exam-date/change-exam-date.component";
import { ProlongExamSubmitionComponent } from "./components/newTickets/prolong-exam-submition/prolong-exam-submition.component";
import { RepeatCourseComponent } from "./components/newTickets/repeat-course/repeat-course.component";
import { RequestTrainingSessionComponent } from "./components/newTickets/request-training-session/request-training-session.component";
import { CancelCourseComponent } from "./components/newTickets/cancel-course/cancel-course.component";
import { ReciptFromRivhitComponent } from "./components/newTickets/recipt-from-rivhit/recipt-from-rivhit.component";
import { OneOnOneSessionComponent } from "./components/newTickets/one-on-one-session/one-on-one-session.component";
import { InternshipComponent } from "./components/newTickets/internship/internship.component";
import { VodProblemComponent } from "./components/newTickets/vod-problem/vod-problem.component";
import { PortalProblemComponent } from "./components/newTickets/portal-problem/portal-problem.component";
import { ChooseDocumentsComponent } from "./components/newTickets/choose-documents/choose-documents.component";
import { NdaObligationComponent } from "./components/newTickets/nda-obligation/nda-obligation.component";
import { CoursesComponent } from './components/newTickets/courses/courses.component';

import { FreezeCourseComponent } from "app/pages/administration/components/newActions/freeze-course/freeze-course.component";
import { ArchiveComponent } from "app/pages/administration/components/newActions/archive/archive.component";
// import { AdministrationComponent } from 'app/pages/administration/administration.component'

import { ActionSelectComponent } from "./components/newActions/action-select/action-select.component";
import { MiscModule } from "../misc/misc.module";
import { StudentGraduationCertificateComponent } from './components/newTickets/student-graduation-certificate/student-graduation-certificate.component';

const routes: Routes = [
  {
    path: "",
    component: TicketListComponent,
    // pathMatch: 'full',
    children: [
      {
        path: "internship",
        component: InternshipComponent,
        pathMatch: "full",
      },
      {
        path: "nda",
        component: NdaObligationComponent,
        pathMatch: "full",
      },
      {
        path: "report-card",
        component: ReportCardComponent,
        pathMatch: "full",
      },
      {
        path: "cert",
        component: StudentCertComponent,
        pathMatch: "full",
      },
      {
        path: "graduation-certificate",
        component: StudentGraduationCertificateComponent,
        pathMatch: "full",
      },
      {
        path: "other",
        component: TechnicalComponent,
        pathMatch: "full",
      },
      {
        path: "vod",
        component: VodProblemComponent,
        pathMatch: "full",
      },
      {
        path: "portal",
        component: PortalProblemComponent,
        pathMatch: "full",
      },
      {
        path: "exam",
        component: ProlongExamSubmitionComponent,
        pathMatch: "full",
      },
      {
        path: "ovo",
        component: OneOnOneSessionComponent,
        pathMatch: "full",
      },
      {
        path: "course",
        component: CoursesComponent,
        pathMatch: "full",
      },
    ],
  },

  {
    path: "action-freeze",
    component: FreezeCourseComponent,
    pathMatch: "full",
  },
];

@NgModule({
  declarations: [
    TicketListComponent,
    StudentCertComponent,
    ReportCardComponent,
    TechnicalComponent,
    TeacherComponent,
    FreezeCourseComponent,
    ArchiveComponent,
    ChooseTicketComponent,
    ActionSelectComponent,
    ChooseSectionComponent,
    ChooseTechIsuesComponent,
    ChooseAdminIsuesComponent,
    ChooseEducationIsuesComponent,
    ChangeExamDateComponent,
    ProlongExamSubmitionComponent,
    RepeatCourseComponent,
    RequestTrainingSessionComponent,
    CancelCourseComponent,
    ReciptFromRivhitComponent,
    OneOnOneSessionComponent,
    InternshipComponent,
    VodProblemComponent,
    PortalProblemComponent,
    ChooseDocumentsComponent,
    NdaObligationComponent,
    CoursesComponent,
    

    // AdministrationComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    SignaturePadModule,
    SharedModule,
    WidgetModule,
    MiscModule
    
  ],
  exports: [
    RouterModule,
    TicketListComponent,
    StudentCertComponent,
    ReportCardComponent,
    TechnicalComponent,
    TeacherComponent,
    FreezeCourseComponent,
    ChooseSectionComponent,
    ArchiveComponent,
  ],
})
export class AdminstrationModule {}
