/* Angular imports. */
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

/* Import the widget module that holds the angular material module libraries. */
import { WidgetModule } from "app/modules/widget/widget.module";
/* Import the shared module that holds the shared components, directive and pipes. */
import { SharedModule } from "app/modules/shared/shared.module";

/* Contact components. */
import { MessageComponent } from "./components/message/message.component";
import { TicketComponent } from "./components/ticket/ticket.component";

/* Other import modules. */
import { QuillModule } from "ngx-quill";
import { MiscModule } from "../misc/misc.module";

@NgModule({
  declarations: [MessageComponent, TicketComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    WidgetModule,
    MiscModule,
    QuillModule,
  ],
  exports: [MessageComponent, TicketComponent],
})
export class ContactModule {}
