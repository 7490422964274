import { EventEmitter, Injectable, Output } from "@angular/core";
import { forkJoin } from "rxjs";
import { ServerApiService } from "app/services/server-api.service";

@Injectable({
  providedIn: "root",
})
export class FileServiceService {
  private homework;
  private homeworkStudents;
  private classwork;
  private classworkCycle;
  private notes;
  private path;
  private loaded: boolean = false;
  @Output() loadend: EventEmitter<any>;
  constructor(private ServerApiService: ServerApiService) {
    this.loadend = new EventEmitter();
  }

  getHomeWork() {
    return this.homework;
  }
  getHomeworkStudents() {
    return this.homeworkStudents;
  }
  getClasswork() {
    return this.classwork;
  }
  getClassworkCycle() {
    return this.classworkCycle;
  }
  getNotes() {
    return this.notes;
  }
  getPath() {
    return this.path;
  }

  getData(type: string, path?, hwsQuery?, lesson?) {
    switch (type) {
      case "homework":
        return this.ServerApiService.getAllFiles(path + "homework/");
      case "homeworkStudents":
        return this.ServerApiService.getAllFiles(
          path + "homeworkStudents/",
          hwsQuery
        );
      case "classwork":
        return this.ServerApiService.getAllFiles(path + "classwork/");
      case "classworkCycle":
        return this.ServerApiService.getAllFiles(path + "classworkCycle/", {
          id: lesson.cyclecode,
        });
      case "notes":
        // To display notes for each course by his cycle code use this string inside getNotes():
        // `${lesson.courseName},${lesson.session_num},${lesson.cyclecode}`

        // To show every note for the same course no matter when he will be opened use this string inside getNotes():
        // `${lesson.courseName},${lesson.session_num}`
        return this.ServerApiService.getNotes(
          `${lesson.courseName},${lesson.session_num}`
        );
      case "path":
        return this.ServerApiService.getCoursePath(lesson.courseName);
    }
  }
  getInitData(path, hwsQuery, lesson) {
    if (!this.loaded) {
      const reqs = [
        this.ServerApiService.getAllFiles(path + "homework/"),
        this.ServerApiService.getAllFiles(path + "homeworkStudents/", hwsQuery),
        this.ServerApiService.getAllFiles(path + "classwork/"),
        this.ServerApiService.getAllFiles(path + "classworkCycle/", {
          id: lesson.cyclecode,
        }),
        this.ServerApiService.getNotes(
          `${lesson.courseName},${lesson.session_num},${lesson.cyclecode}`
        ),
        this.ServerApiService.getCoursePath(lesson.courseName),
      ];

      forkJoin(reqs).subscribe(
        ([
          homework,
          homeworkStudents,
          classwork,
          classworkCycle,
          notes,
          path,
        ]) => {
          this.homework = homework;
          this.homeworkStudents = homeworkStudents;
          this.classwork = classwork;
          this.classworkCycle = classworkCycle;
          this.notes = notes;
          this.path = path;
          this.loadend.emit();
          this.loaded = true;
        }
      );
    }
  }
}
