import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./pages/login/login.component";
import { AuthGuard } from "./auth.guard";
import { UserProfileComponent } from "./pages/user-profile/user-profile.component";
import { VideoSoftComponent } from "./pages/misc/components/videosoft/videosoft.component";
import { VideoPageComponent } from "./pages/students/pages/video-page/video-page.component";
import { CoursesContainerComponent } from "./pages/misc/components/courses-container/courses-container.component";
import { AdministrationComponent } from "./pages/administration/administration.component";
import { SolaredgeCourseListComponent } from "./pages/solaredge-course-list/solaredge-course-list.component";
import { OutsourceCalendarComponent } from "./pages/outsource-calendar/outsource-calendar.component";
import { OutsourceCoursesListComponent } from "./pages/outsource-courses-list/outsource-courses-list.component";
import { OutsourceStudentsDataComponent } from "./pages/outsource-students-data/outsource-students-data.component";
import { SolaredgeStudentsComponent } from "./pages/solaredge-students/solaredge-students.component";
import { AdminPageComponent } from "./pages/admin-page/admin-page.component"
import {InternshipComponent} from "./pages/students/pages/internship/internship.component"
import {RecruitmentComponent} from "./pages/students/pages/recruitment/recruitment.component"
import {PlacementComponent} from "./pages/students/pages/placement/placement.component"
import { GraduationCertificateComponent } from "./pages/students/pages/graduation-certificate/graduation-certificate.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  { path: "login", component: LoginComponent },
  {
    path: "user-profile",
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "videosoft",
    component: VideoPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "internship",
    component: InternshipComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "recruitment",
    component: RecruitmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "placement",
    component: PlacementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "courses-container",
    component: CoursesContainerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "graduation_certificate",
    component: GraduationCertificateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "administration",
    component: AdministrationComponent,
    loadChildren: () =>
      import("app/pages/administration/adminstration.module").then(
        (m) => m.AdminstrationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "table-list",
    loadChildren: () =>
      import("app/pages/students/students.module").then(
        (m) => m.StudentsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "teacher-list",
    loadChildren: () =>
      import("app/pages/teachers/teachers.module").then(
        (m) => m.TeachersModule
      ),
    //canActivate: [AuthGuard],
  },
  {
    path: "solaredge-course-list",
      component: SolaredgeCourseListComponent,
      canActivate: [AuthGuard],
  },
  {
    path: "solaredge-students/:id",
      component: SolaredgeStudentsComponent,
      canActivate: [AuthGuard],
  },
  {
    path: "outsource-course-list",
      component: OutsourceCoursesListComponent,
      canActivate: [AuthGuard],
  },
  {
    path: "outsource-calendar",
      component: OutsourceCalendarComponent,
      canActivate: [AuthGuard],
  },
  {
    path: "outsource-students-data",
      component: OutsourceStudentsDataComponent,
      canActivate: [AuthGuard],
  },
  {
    path: "admin-page",
      component: AdminPageComponent,
      canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
