import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-choose-education-isues',
  templateUrl: './choose-education-isues.component.html',
  styleUrls: ['./choose-education-isues.component.css']
})
export class ChooseEducationIsuesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
