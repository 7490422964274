import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { MatAccordion } from "@angular/material/expansion";
import { MatStepper } from "@angular/material/stepper";
import { Observable } from "rxjs/Observable";
import { ServerApiService } from "app/services/server-api.service";
import { StudentDataService } from "app/services/students/student-data.service";
import { TicketsService } from "app/services/contact/tickets/tickets.service";
import { TicketType } from "app/models/classes/ticketType";

@Component({
  selector: "app-ticket-list",
  templateUrl: "./ticket-list.component.html",
  styleUrls: ["./ticket-list.component.css"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class TicketListComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  tickets$: Observable<any>;
  tickets: any;
  newTicket: boolean;
  newAction: boolean;
  newSection: string;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  ticket_type_1_list: TicketType[];
  ticket_type_2_list: TicketType[];
  constructor(
    private ServerApiService: ServerApiService,
    private ticketsService: TicketsService,
    private StudentDataService: StudentDataService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.tickets$ = this.ServerApiService.getTickets(this.getMyId());
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ["", Validators.required],
      secCtrl: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secCtrl: ["", Validators.required],
    });

    this.getTicketType1List();
    // this.getTicketType2List();


    this.tickets$.subscribe((data)=>{
      console.log('===============> this.tickets$',data)
     // this.tickets = data.reverse()
    })
  }
  getMyId() {
    return this.StudentDataService.getStudentInfo().studentID;
  }
  test(v){
    console.log('test============>',v)
  }

  setActiveSection(section: string) {
    console.log(' setActiveSection ============>',section);
    this.ticketsService.activeSection = section;
    this.getTicketType2List();
  }
  getActiveSection() {
    return this.ticketsService.activeSection;
  }
  getTicketType1List() {
    this.ticket_type_1_list = this.ticketsService.ticket_type_1;
  }
  getTicketType2List() {
    this.ticket_type_2_list =  this.ticketsService.ticket_type_2[this.getActiveSection()];
    console.log('ticket_type_2_list==========>', this.ticket_type_2_list,   this.getActiveSection(),  this.ticketsService.ticket_type_2  );
    console.log('this.ticketsService.ticket_type_2',  this.ticketsService.ticket_type_2  );
  }

  onSubmit(stepper: MatStepper) {
    console.log('*******************onSubmit ********************************',this.getActiveSection() );
    this.firstFormGroup.setValue({ firstCtrl: this.getActiveSection()});
    console.log('onSubmit ticket',this.firstFormGroup.value);
    stepper.next();
  }
}
