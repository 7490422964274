import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { FooterComponent } from "./footer/footer.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { CourseCardComponent } from "./course-card/course-card.component";
import { FileComponent } from "./file/file.component";
import { ModalComponent } from "./modal/modal.component";
import { UploadFilesComponent } from "./upload-files/upload-files.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { FileUploadComponent } from './file-upload/file-upload.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@NgModule({
  imports: [CommonModule, RouterModule,MatIconModule,MatProgressBarModule],
  declarations: [
    CourseCardComponent,
    FileComponent,
    FooterComponent,
    ModalComponent,
    NavbarComponent,
    SidebarComponent,
    UploadFilesComponent,
    FileUploadComponent,
  ],
  exports: [
    CourseCardComponent,
    FileComponent,
    FooterComponent,
    ModalComponent,
    NavbarComponent,
    SidebarComponent,
    UploadFilesComponent,
    FileUploadComponent
  ],
})
export class ComponentsModule {}
