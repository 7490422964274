import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Observable } from "rxjs/Observable";
import { ServerApiService } from "app/services/server-api.service";
import { StudentDataService } from "app/services/students/student-data.service";

@Component({
  selector: "app-report-card",
  templateUrl: "./report-card.component.html",
  styleUrls: ["./report-card.component.css"],
})
export class ReportCardComponent implements OnInit {
  reportCard$: Observable<any>|null = null;

  

  constructor(
    private ServerApiService: ServerApiService,
    private location: Location,
    private StudentDataService: StudentDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.ServerApiService.getMarkSheet(
      this.StudentDataService.getStudentInfo().studentID
    ).subscribe((data: any) => {
      this.reportCard$ = data.status
        ? this.ServerApiService.getDocument(
            `${this.StudentDataService.getStudentInfo().studentID}-marks.pdf`,
            "/mark_sheets"
          )
        : null;
      console.log(data, data.status);
    });
  }

  newReportCard(theurl) {
    //   const ticket = {
    //     msg: 'Request to get report card',
    //     owner: this.StudentDataService.getStudentInfo().studentID,
    //     customer: this.StudentDataService.getStudentInfo().studentID,
    //     the_type: "document request",
    //     process: {
    //       status: 'open',
    //       open_date: new Date(Date.now()),
    //       close_date: null
    //     },

    //   };
    //   const actions = [
    //     {
    //     owner: this.StudentDataService.getStudentInfo().studentID,
    //     the_type: 'request',
    //     timestamp: new Date(Date.now()),
    //     description: 'new request to get report card',
    //     data: null
    //   },
    //   {
    //     owner: "system",
    //     the_type: 'close',
    //     timestamp: new Date(Date.now()),
    //     description: 'document supplied',
    //     data: null
    //   }
    // ]
    const url = URL.createObjectURL(
      new Blob([theurl], { type: "application/pdf" })
    );
    const ticketDetails = {
      owner: this.StudentDataService.getStudentInfo().studentID,
      customer: this.StudentDataService.getStudentInfo().studentID,
      sectionOf: "grades_sheet",
    };
    // this.ServerApiService.newTicket(ticket)
        // .subscribe((data: any) => {
      //    this.ServerApiService.newAction(actions, data.data._id)
      // .subscribe((data) => {
      //   window.open(url);
      //   this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      //   this.router.onSameUrlNavigation = "reload";
      //   this.router.navigate(["/administration"]);
      // });
  }
  goBack() {
    this.location.back();
  }
}
