import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outsource-courses-list',
  templateUrl: './outsource-courses-list.component.html',
  styleUrls: ['./outsource-courses-list.component.css']
})
export class OutsourceCoursesListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
