import { Component, OnInit } from "@angular/core";
import { StatesManagerService } from "app/services/misc/states-manager.service";

@Component({
  selector: "app-choose-section",
  templateUrl: "./choose-section.component.html",
  styleUrls: ["./choose-section.component.css"],
})
export class ChooseSectionComponent implements OnInit {
  constructor(private statesManagerService: StatesManagerService) {}

  ngOnInit(): void {}

  setSection(section) {
    this.statesManagerService.setSection(section);
  }
}
